import React from 'react';
import {useState,useEffect } from 'react';
import styles from './resetPasswordModal.module.css'
import { callPatchApiSevices } from '../../webServices/api';


const ResetPasswordModal = (props)=> {

    const[newPassword, setNewPassword] = useState();
    const[err,setErr] = useState(false);


    function handleNameChange(e){
        setNewPassword(e.target.value);
    }

    function resetPasswordHandler(e){
   console.log(e.target.type)
        callPatchApiSevices(`/user/users/${props?.selectedRowDataId}`,
        {
            password:newPassword
        },
        (response)=>{
            if(response.status === 200){
                props.onHide(e);
            }else{
                alert('error updating password')
            }
        },
        (err)=>{
            alert(err);
        }
        )
        
    }


  return (
    <div className={styles.modal} value={'overlay'} onClick={props.onHide}>
    <div className={styles.modalContent} >
        <div className={styles.circle}></div>
        <span>Reset Password for `{props.selectedRowData?.name?props.selectedRowData?.name : props.selectedRowData?.original?.name ? props.selectedRowData?.original?.name:''}`</span>
            <input
                    placeholder='New Password'
                      spellCheck={false}
                      type="text"
                      value={newPassword}
                      onChange={handleNameChange}
                    />
      
        <button onClick={resetPasswordHandler} disabled={!newPassword}  type='submit' value={'resetBtn'}>Reset</button>
   </div>
    </div>
  );
};

export default ResetPasswordModal;
