import styles from './forgot-password.module.css'
import { useNavigate,useParams } from "react-router-dom";
import { useState } from 'react';
import { callPostApiServices } from '../../webServices/api';

function ForgotPasswordFromMail(props){

  const[newPassword,setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState("");
  const [pwChangedSuccess, setPwChangedSuccess] = useState(false);
  const navigate = useNavigate();
  const{userId,tkn} = useParams();

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setNewPassword(inputValue);
    if (!validatePassword(inputValue)) {
      setPasswordError("Password needs 8+ characters, including an uppercase letter, a number, and a special character");
    } else {
      setPasswordError("");
    }
  };


  function resetNewPassword(){
    if(newPassword){
        callPostApiServices(`/user/reset-password  `,
        {
            userId:userId,
            token:tkn,
            password:newPassword
        },
        (response)=>{
            if(response.status === 200 && response.data.success)
                console.log(response);
                setPwChangedSuccess(true);
            
        },
        (err)=>{
            setPasswordError('server error');
        }
        )
    }
  }

  function linkSentHandler(e){
    navigate('/')
  setPwChangedSuccess(false);

 }

    return(
    <div className={styles.loaderPageContainer} >
            <div className={styles.modal}>
    <div className={styles.modalContent} >
        <div className={styles.circle}>
            {/* <img src={projectNameIcon} /> */}
        </div>
      {!pwChangedSuccess &&
      <>  
        <span>Reset Password</span>
        <div className={styles.inputCont}>
                    <input
                      spellCheck={false}
                      type="text"
                      value={newPassword}
                      onChange={handlePasswordChange}
                    />
                    {passwordError && <span className={styles.errMsgSpan} >{passwordError}</span>}
        </div>
        <button onClick={resetNewPassword} disabled={passwordError}  type='submit'>Reset Password</button>
      </>
      } 

      {pwChangedSuccess && 
      <>
        <span>Password Updated Successfully</span>
        <button className={styles.reqRess} onClick={linkSentHandler}  type='submit'>Ok</button>
      </>
      }    
   </div>
    </div>
    </div>
    )
}

export default ForgotPasswordFromMail;