import styles from '../viewQuotationPage.module.css'
import Table from '../../components/table/table';
import QuotPreview from '../../images/quotPreview.png'
import View from '../../images/view.png'
import Download from '../../images/download.png'
import Bin from '../../images/bin.png'
import Call from '../../images/call.png'
import { useNavigate } from "react-router-dom";
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintableComponent from './printableComponent';
import { useEffect, useState } from 'react';
import { callGetApiSevices } from '../../webServices/api';
import PdfModal from './PdfModal';
import PdfModalForPreview from './PdfModalForPreview';
import PdfMiniatureAuth from './PdfMiniatureAuth';

const PrintButton = ({pdfData, isOpen, setModalOpen}) => {
    const navigate = useNavigate();
    const componentRef = useRef();
    const[open,setOpen]= useState(false);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            // Close the modal after printing
            setOpen(false);
        },
    });

    useEffect(()=>{
        if(isOpen){
            setOpen(true)
        }
    },[isOpen])

    function closeModal(){
        setOpen(false);
    }

console.log(pdfData)
    return (
    <>
        { open &&<PdfModal onPrs={handlePrint} pdfData={pdfData} onClose={closeModal} ref={componentRef} />}
        <div className={styles.bottomOptions}>
                        <img src={View} />
                        <img src={Call} onClick={()=>navigate('/contact-us')} />
                    </div> 
        </>
    );
};


function BottomContainer(props){

    const[quotationTableData,setQuotationTableData] = useState([]);
    const[pdfData,setPdfData] = useState();
    const[isModalOpen, setModalOpen] = useState(false);
    const[isModalPreviewOpen, setModalpreviewOpen] = useState(false);
    const[isModalOpenPrint, setModalOpenPrint] = useState(false);
    const[imgUrl,setImgUrl] = useState();
    const[quotationTotal, setQuotationTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState();
    const[offset,setOffset] = useState(1);
    const itemsPerPage = 10;

    const navigate = useNavigate();
    const tkn = localStorage.getItem('token')

    // function dataToGeneratePdf(data){
    //     setPdfData(data);
    // }

    function getQuotationForUser(page){
        setOffset((page - 1)*itemsPerPage);
        callGetApiSevices(`/quotations/?user=${localStorage.getItem('userId')}&limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`,
        (response)=>{
            if(response.status === 200){
                console.log(response)
                setQuotationTotal(response.data.count);
                setQuotationTableData(response.data.data);
            }else{
                alert('something went wrong')
            }
        },
        (err)=>{
            alert(err);
        }
        )
    }

    useEffect(()=>{
        console.log(2);
        if(!currentPage && tkn){
            setCurrentPage(1);
            return;
        }
        getQuotationForUser(currentPage)
    },[currentPage,tkn])

    const handleModal =()=>{
        console.log(3);
        setModalOpen(!isModalOpen);
    }

    const handleCloseModal = (e) =>{
        if (e.target.classList.contains('pdfModal_modal__hdWd9')) {
            setModalOpen(false);
            console.log('hloooooooooo')
          }
    } 

    const handleCloseModalPreview = (e) =>{
        console.log(4);
            setModalpreviewOpen(false);
    } 
    // useEffect(()=>{
    //     console.log(5);
    //     if(pdfData){
    //         setModalpreviewOpen(true);
    //         setTimeout(()=>{
    //                 handleCloseModalPreview();
    //         },1000)
    //     }
 
    // },[pdfData])

    const handlePageChangePrevious = (newPage) => { 
        console.log(6);
        setCurrentPage(newPage);
    };

    const handlePageChangeNext = (newPage) => {
        console.log(7);
        setCurrentPage(newPage);
    };

    console.log('curr pg', currentPage)

    return(
    <>
        {/* {isModalPreviewOpen && <PdfModalForPreview pdfData={pdfData} setImgUrl={setImgUrl} isOpen={isModalPreviewOpen} onClose={handleCloseModalPreview}/>} */}
         {/* {isModalOpen && <PdfModal pdfData={pdfData} isOpen={isModalOpen} onClose={handleCloseModal}/>} */}
            <div className={styles.bottomContainer}>
        <div className={styles.bottomContainerSub}>
            <div className={styles.bottomContBtn}>
                <button 
                onClick={()=>{
                    props.setHomeTypePg(true);
                    navigate('/home-type-auth')
                }} 
                type='submit'
                style={{width:'17rem'}}
                >Create New Quotation</button>
            </div>

            <div className={styles.bottomContTableQuot}>
                <div className={styles.bottomContTable}>
                    <div className={styles.tableDiv}>
                    <Table quotationTableData={quotationTableData} setPdfData={setPdfData} offset={offset}/> 
                    </div>
                    <div className={styles.paginationContainer}>
                <button onClick={() => handlePageChangePrevious(currentPage - 1)} disabled={currentPage === 1}>
                Previous
                </button>
                <div className={styles.currentPageCont} >
                    <span>{currentPage}</span>
                </div>
                
                <button onClick={() => handlePageChangeNext(currentPage + 1)} disabled={currentPage*10>=quotationTotal}>
                Next
                </button>
            </div>  
                </div>
                <div className={styles.quoteImgCont} onClick={handleModal}>
                
                    {/* {imgUrl && <img src={imgUrl} style={{width:'100%',height:'100%',objectFit:'cover'}}/>} */}
                  
                {pdfData &&<> <PdfMiniatureAuth pdfData={pdfData} isOpen={isModalPreviewOpen} onClose={handleCloseModalPreview} />
                  <PrintButton pdfData={pdfData} isOpen={isModalOpen} setModalOpen={setModalOpen} />
                    {/* <div className={styles.bottomOptions}>
                        <img src={View} />
                        <img src={Download} />
                        <img src={Bin} />
                        <img src={Call} />
                    </div> */}
                    </>
                }
                </div>
            </div>
        </div>      
    </div>
    </>

    )
}



export default BottomContainer;