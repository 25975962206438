import styles from '../settingsPage.module.css'
import { useEffect, useState } from 'react';
import { callGetApiSevices } from '../../../webServices/api';
import { callPatchApiSevices } from '../../../webServices/api';
import PartCountTable from '../../../components/table/partCountTable';

function PartCount(){

    const [selectedPriority, setSelectedPriority] = useState('Lifestyle');
    const [selectedProperty, setSelectedProperty] = useState('Condominium');
    const [partCountData,setPartCountData] = useState([]);
    const [idArr,setIdArr] = useState([]);
    const [changedDataArr,setChangedDataArr] = useState([]);
    const [onlyChangedData, setOnlyChangedData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [cancelClick,setCancelClick] = useState(false);

    const handleEditButtonClick = () => {
      setIsEditing(!isEditing);
      setCancelClick(false);
    };

    useEffect(()=>{
        if(!isEditing && !cancelClick){
            const filteredData = changedDataArr.filter(item => idArr.includes(item._id));
            setOnlyChangedData(filteredData);
          }
    },[isEditing]);


    useEffect(()=>{
        if(onlyChangedData.length >0){
            onlyChangedData.map((el)=>{

                callPatchApiSevices(`/part-counts/${el._id}`,
                {
                    "propertyType":el.propertyType,
                    "priority":selectedPriority,
                    "roomCount":el.roomCount,
                    "Smart Home Hub":el.roomData['Smart Home Hub'],
                    "Digital Door Lock":el.roomData['Digital Door Lock'],
                    "IP Camera":el.roomData['IP Camera'],
                    "1G Smart Switch":el.roomData['1G Smart Switch'],
                    "2G Smart Switch":el.roomData['2G Smart Switch'],
                    "3G Smart Switch":el.roomData['3G Smart Switch'],
                    "Contact Sensor":el.roomData['Contact Sensor'],
                    "Motion Sensor":el.roomData['Motion Sensor'],
                    "Remote Switch":el.roomData['Remote Switch'],
                    "Human Presence Sensor":el.roomData['Human Presence Sensor'],
                    "Smoke Detector":el.roomData['Smoke Detector'],
                    "Air Con Controller (IR)":el.roomData['Air Con Controller (IR)']
                },
            (response)=>{
                if(response.status ===200){
                    console.log(response);
                    fetchData();
                    setIdArr([]);
                    setChangedDataArr([]);
                    console.log(onlyChangedData,idArr)
                }
            },(err)=>{
                console.log(err);
            } 
                )
            })
        }
    },[onlyChangedData])
    

    const handleSelectPriorityChange = (event) => {
      setSelectedPriority(event.target.value);
    };

    const handleSelectPropertyChange = (event) => {
        setSelectedProperty(event.target.value);
      };

      const fetchData = ()=>{
        callGetApiSevices(`/part-counts/?priority=${selectedPriority}&propertyType=${selectedProperty}`,(response)=>{
            if(response.status === 200){
                setPartCountData(response.data);
            }else{
                alert('error fetching part counts')
            }
    },
    (err)=>{
        alert(err)
    })
      }

      useEffect(()=>{
        fetchData();
      },[selectedPriority,selectedProperty])


      const updatePartCountData = (idArr,dataArr) =>{
        setIdArr(idArr);
        setChangedDataArr(dataArr)
      }

      const handleCancelButtonClick = ()=>{
        setIsEditing(false);
        setCancelClick(true);
        fetchData();
      }

return(
    <div className={styles.partCountCont} >
        <div className={styles.selectionCont} >
            <div>
            <label htmlFor="mySelect">Priority</label>
                <select style={{fontSize:'1.2rem'}} id="mySelect" value={selectedPriority} onChange={handleSelectPriorityChange}>
                    <option value="Lifestyle">Lifestyle</option>
                    <option value="Eldercare">Eldercare</option>
                    <option value="Childcare">Childcare</option>
                    <option value="Security">Security</option>
                    <option value="Rental">Rental</option>
                </select>
            </div>

            <div>
            <label htmlFor="mySelect">Property Type</label>
                <select style={{fontSize:'1.2rem'}} id="mySelect" value={selectedProperty} onChange={handleSelectPropertyChange}>
                    {/* <option value="">Select...</option> */}
                    <option value="Condominium">Condominium</option>
                    <option value="HDB">HDB</option>
                    <option value="Landed">Landed</option>
                </select>
            </div>

            <div className={styles.btnCont}>
           { isEditing && <button className={styles.barContRedbutton} onClick={handleCancelButtonClick}>Cancel</button> }
            <button className={styles.barContbutton} disabled={partCountData.length===0} onClick={handleEditButtonClick}>{isEditing ? 'Save' : 'Edit'}</button>
            </div>
        </div>
        <div className={styles.tableCont} >
        {/* <div className={styles.tableContSub} > */}
        <PartCountTable fetchData={fetchData} selectedPriority={selectedPriority} selectedProperty={selectedProperty} isEditing={isEditing} partCountData={partCountData} updatePartCountData={updatePartCountData}/>
        {/* </div> */}
        </div>
    </div>
    )
}

export default PartCount;