import styles from './quotationPage.module.css'
import Logo from '../images/logo.png'
import DragSlider from '../components/dragSlider/dragSlider';
import LoginPage from '../components/loginPage/loginPage';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import SignUp from '../components/signUp/signUp';
import PdfModalForPreview from '../viewQuotationPage/components/PdfModalForPreview';
import PdfMiniature from '../viewQuotationPage/components/PdfMiniature';

function QuotationPage(props){

    const[showLoginPage, setShowLoginPage] = useState(false);
    const[signUpPage, setSignUpPage] = useState(false);
    const[pdfData,setPdfdata] = useState();
    const[isModalPreviewOpen, setModalpreviewOpen] = useState(false);
    const[imgUrl,setImgUrl] = useState();

    const navigate = useNavigate();

    useEffect(()=>{
        let data = JSON.parse(localStorage.getItem('newUserQuotationDetails'));
        console.log(data)
        setPdfdata(data);
    },[])

    function signUpFormHandler(){
        setShowLoginPage(!showLoginPage);
        setSignUpPage(!signUpPage);
    }

    function loginFromSignUp(){
        setShowLoginPage(!showLoginPage);
        setSignUpPage(!signUpPage);
    }

    function signUpFromLogin(){
        setShowLoginPage(!showLoginPage);
        setSignUpPage(!signUpPage);
    }


    function loginPageHandler(){
        setShowLoginPage(!showLoginPage);
    }

    function viewQuotationHandler(){
        const tkn = localStorage.getItem('token');
        if(tkn){
            navigate('/viewQuotation');
            localStorage.removeItem('homeType');
            localStorage.removeItem('progress');
            localStorage.removeItem('propertyType');
            localStorage.removeItem('rooms');
            localStorage.removeItem('priorityType');
            localStorage.removeItem('newUserQuotationDetails')
            localStorage.removeItem('projectName')
            // localStorage.setItem('token',tkn)
        }else{
            loginPageHandler();
        }
    }

    const handleCloseModalPreview = (e) =>{
        setModalpreviewOpen(false);
} 

    useEffect(()=>{
        if(pdfData){
            setModalpreviewOpen(true);
            setTimeout(()=>{
                    handleCloseModalPreview();
            },1000)
        }
 
    },[pdfData])

    return(
        <>
        {/* {isModalPreviewOpen && pdfData && <PdfModalForPreview pdfData={pdfData} setImgUrl={setImgUrl} isOpen={isModalPreviewOpen} onClose={handleCloseModalPreview}/>} */}
        {showLoginPage && <LoginPage updateTkn={props.updateTkn} signUpFormHandler={signUpFormHandler} setShowLoginPage={setShowLoginPage} />}
        {signUpPage && <SignUp signUpFromLogin={signUpFromLogin} loginFromSignUp={loginFromSignUp} setSignUpPage={setSignUpPage} />}
  { !showLoginPage && !signUpPage &&   <div className={styles.quotationPageContainer}>
                    <div className={styles.quotationPageContainerSub} >
                        <div className={styles.headerContainer}>
                            <img src={Logo} onClick={viewQuotationHandler}/>     
                        </div>

            <div className={styles.midContainer}>
                <div className={styles.quotationImgCont}>
                {/* {imgUrl && <img src={imgUrl} style={{width:'100%',height:'100%',objectFit:'cover'}}/>} */}
                {pdfData && <PdfMiniature pdfData={pdfData} setImgUrl={setImgUrl} isOpen={isModalPreviewOpen} onClose={handleCloseModalPreview}/>}
                </div> 

                <button type='submit' onClick={viewQuotationHandler} >View Quotation</button>

                <div>
                    <DragSlider />
                </div>
                
            </div>
        </div>
    </div>
}
        </>

    );
}

export default QuotationPage;