import styles from '../adminPage.module.css'
import Logo from '../../images/logo.png'
import User from '../../images/user.png'
import { useState } from 'react';

function HeaderContainer(){
    const [showLogout, setShowLogout] = useState(false);

    const openLogout = ()=>{
        setShowLogout(!showLogout);
    }

    const handleLogout = ()=>{
        localStorage.clear();
        setShowLogout(false);
        window.location.reload();
    }



    return(<div className={styles.headerContainer}>
        <img src={Logo} />
        <div className={styles.headerBtnCont}>
        <img className={styles.user} src={User} onClick={openLogout} />
        {showLogout && (
        <div className={styles.logoutPopup}>
          <p onClick={handleLogout}>Logout</p>
        </div>
      )}
        </div>
    </div>)
}

export default HeaderContainer;