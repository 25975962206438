import HomePage from './homePage/homePage'; 
import HomeTypePage from './homeType/homeTypePage';
import PropertyTypePage from './propertyType/propertyTypePage';
import PriorityPage from './priorityPage/priorityPage';
import LoaderPage from './loaderPage/loaderPage';
import QuotationPage from './quotationPage/quotationPage';
import AdminPage from './adminPage/adminPage';
import ForgotPasswordFromMail from './components/forgot-password/forgot-password';
import ContactUs from './contactUs/contactUs';
import './App.css';
import {
 
  Routes,
  Route,
  BrowserRouter,
  Navigate
} from "react-router-dom";
import styles from './main.module.css'
import Protected from './util/protected';
import ViewQuotationPage from './viewQuotationPage/viewQuotationPage';
import { useEffect, useState } from 'react';

function App() {

const[homeTypePg, setHomeTypePg] = useState(false);
const[propertyTypePg, setPropertyTypePg] = useState(false);
const[priorityTypePg, setPriorityTypePg] = useState(false);
const[loaderndQuotPg,setLoaderndQuotPg] = useState(false);
const[quotationPageLoad, setQuotationPageLoad] = useState(false);
const[token,setToken]=useState(null);

window.addEventListener('beforeunload', function() {
  if(localStorage.getItem('token')){
    return
  }
  localStorage.clear();
});

function updateTkn(tkn){
  setToken(tkn);
}


  return (
    <div className={styles.mainContainer}>
        <BrowserRouter>

    { localStorage.getItem("token") || token ?(
      localStorage.getItem('role') === 'customer' ?
    <Routes>
  
        { homeTypePg &&    <Route
          exact
          path="/home-type-auth"
          element={<HomeTypePage token={token} setPropertyTypePg={setPropertyTypePg} />}
        />
        }
         {
          propertyTypePg &&
          <Route
          exact
          path="/property-type-auth"
          element={<PropertyTypePage token={token} setPriorityTypePg={setPriorityTypePg} />}
        />
         }      
         { priorityTypePg &&       <Route
          exact
          path="/priority-type-auth"
          element={<PriorityPage token={token} setLoaderndQuotPg={setLoaderndQuotPg} />}
        />
                        }  
        { loaderndQuotPg && <Route
          exact
          path="/loaderPage-auth"
          element={<LoaderPage token={token} setQuotationPageLoad={setQuotationPageLoad} />}
        />
        }
        { quotationPageLoad && <Route
          exact
          path="/quotation-auth"
          element={<QuotationPage token={token} updateTkn={updateTkn} />}
        />
        }
              <Route
        exact
        path="/contact-us"
        element={<ContactUs />}
      />  
           <Route
          exact
          path="/viewQuotation"
          element={
            <Protected isLoggedIn={localStorage.getItem("token") || token ? true : false}>
            <ViewQuotationPage  setHomeTypePg={setHomeTypePg} />
          </Protected>
          }
        />

      {/* <Route
          exact
          path="/admin"
          element={
            // <Protected isLoggedIn={localStorage.getItem("token") || token ? true : false}>
            <AdminPage />
          // </Protected>
          }
        /> */}
        
              <Route
          exact
          path="*"
          element={<Navigate to="/viewQuotation" replace />}
        />
      </Routes>:


      <Routes>

        <Route
        exact
        path="/admin"
        element={
          <Protected isLoggedIn={localStorage.getItem("token") || token ? true : false}>
          <AdminPage />
        </Protected>
        }
        />
        
        <Route
          exact
          path="*"
          element={<Navigate to="/admin" replace />}
        />
      </Routes>
      
    )
      :
      <Routes>
      <Route
        exact
        path="/"
        element={<HomePage updateTkn={updateTkn} setHomeTypePg={setHomeTypePg} />}
      />
      { homeTypePg &&    <Route
        exact
        path="/home-type"
        element={<HomeTypePage token={token} setPropertyTypePg={setPropertyTypePg} />}
      />
      }
       {
        propertyTypePg &&
        <Route
        exact
        path="/property-type"
        element={<PropertyTypePage token={token} setPriorityTypePg={setPriorityTypePg} />}
      />
       }      
       { priorityTypePg &&       <Route
        exact
        path="/priority-type"
        element={<PriorityPage token={token} setLoaderndQuotPg={setLoaderndQuotPg} />}
      />
                      }  
      { loaderndQuotPg && <Route
        exact
        path="/loaderPage"
        element={<LoaderPage token={token} setQuotationPageLoad={setQuotationPageLoad} />}
      />
      }
      { loaderndQuotPg && <Route
        exact
        path="/quotation"
        element={<QuotationPage token={token} updateTkn={updateTkn} />}
      />
      }    
        <Route
        exact
        path="/forgot-password/:userId/:tkn"
        element={<ForgotPasswordFromMail />}
      />  

        <Route
        exact
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
    }

   
    </BrowserRouter>
    </div>
  );
}

export default App;
