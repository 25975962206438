import React, { useState } from 'react';
import styles from './chartDataModal.module.css'
import AdminQuotationTable from '../table/adminQuotationTable';

const ChartDataModal = ({offset,totalQuotationsCount,currentPage, setCurrentPage, selectedMonth, selectedYear,lastDate, montlyQuotationTableData, showModal, closeModal}) => {

    

  const closeModalHandler = ()=>{
    closeModal();
  }

  const handlePageChangePrevious = (newPage) => { 
    setCurrentPage(newPage);
};

const handlePageChangeNext = (newPage) => {
    setCurrentPage(newPage);
};



  return (
    <div className={styles.modal} >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader} >
        <span className={styles.heading} onClick={closeModalHandler}>Quotations</span>
        <span className={styles.date} >{`${selectedMonth} 01, ${selectedYear} - ${selectedMonth} ${lastDate}, ${selectedYear}`}</span>
        <span className={styles.close} onClick={closeModalHandler}>&times;</span>
        </div>

        <div className={styles.modalContentSub} >
        <div className={styles.bottomCont} >
        <div className={styles.bottomContTable}>
            <AdminQuotationTable offset={offset} adminQuotationTableData={montlyQuotationTableData|| []}/>
        </div> 

        </div>
        </div>
        <div className={styles.modalBottomCont}>
        <div className={styles.paginationContainer}>
                <button onClick={() => handlePageChangePrevious(currentPage - 1)} disabled={currentPage === 1}>
                Previous
                </button>
                <div className={styles.currentPageCont} >
                    <span>{currentPage}</span>
                </div>
                
                <button onClick={() => handlePageChangeNext(currentPage + 1)} disabled={currentPage *10 >= totalQuotationsCount}>
                Next
                </button>
            </div>  
        </div>
      </div>
    </div>
  );
};

export default ChartDataModal;
