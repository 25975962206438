import styles from '../settingsPage.module.css'
import { useEffect, useState } from 'react';
import PartPriceTable from '../../../components/table/partPriceTable';
import { callGetApiSevices } from '../../../webServices/api';
import { callPatchApiSevices } from '../../../webServices/api';

function PartPrice(){

    const [isEditing, setIsEditing] = useState(false);
    const[partPriceData,setPartPriceData] = useState([]);
    const[idArr,setIdArr] = useState([]);
    const[changedDataArr,setChangedDataArr] = useState([]);
    const[onlyChangedData, setOnlyChangedData] = useState([]);
    const [cancelClick,setCancelClick] = useState(false);

    const handleEditClick = () => {
      setIsEditing(!isEditing);
      setCancelClick(false);
    };

    useEffect(()=>{
        if(!isEditing && !cancelClick ){
            console.log('meee');
            const filteredData = changedDataArr.filter(item => idArr.includes(item._id));
            setOnlyChangedData(filteredData);
          }
    },[isEditing])



    useEffect(()=>{  
        console.log('only', onlyChangedData)
        if(onlyChangedData.length >0){
            onlyChangedData.map((el)=>{
                // try{
                    callPatchApiSevices(`/part/${el._id}`,
                    {
                            brand: el.brand,
                            model: el.model,
                            price:el.price,
                            description: el.description,
                            UOM: el.UOM,
                    },(response)=>{
                        if(response.status ===200){
                            console.log(response)
                            setIdArr([]);
                            setChangedDataArr([]);
                        }
                    },(err)=>{
                        console.log(err);
                    }
                    )
                // }catch(err){
                //     console.log(el);
                // }            
            })
        }
    },[onlyChangedData])

    const updatePartPriceData = (idArr,dataArr) =>{
        setIdArr(idArr);
        setChangedDataArr(dataArr)
    }

    // console.log(onlyChangedData)

    const fetchData = ()=>{
        callGetApiSevices('/get-parts',(response)=>{
            if(response.status === 200){
                setPartPriceData(response.data)
            }else{
                alert('error fetching part prices')
            }
    },
    (err)=>{
        alert('error fetching part prices')
    }
    )
    }

    useEffect(()=>{
        fetchData();
    },[])

    const handleCancelButtonClick = ()=>{
        setIsEditing(false);
        setCancelClick(true);
        fetchData();
    }
  

return(
    <div className={styles.partCountCont} >
        
        <div className={styles.btnCont}>
           { isEditing && <button className={styles.barContRedbutton} onClick={handleCancelButtonClick}>Cancel</button> }
           <button className={styles.barContbutton}  onClick={handleEditClick} >{isEditing ? 'Save' : 'Edit'}</button>
        </div>
       
        <div className={styles.tableCont} >
        {/* <div className={styles.tableContSub} > */}
        <PartPriceTable isEditing={isEditing} partPriceData={partPriceData} updatePartPriceData={updatePartPriceData} />
        {/* </div> */}
        </div>
    </div>
    )
}

export default PartPrice;