import styles from '../homePage.module.css'
import { useNavigate } from "react-router-dom";

function BottomContainer(props){

    const navigate = useNavigate();

function startBtnHandler(){
    props.setHomeTypePg(true);
    navigate('/home-type')
}

    return(<div className={styles.bottomContainer}>
        <div className={styles.bottomContainerSub}>
            <div>
            <span>Your One Stop Start Home Planning Platform</span>
            </div>
        <div>
        <span>Want to smart your smart home but don’t know how? Let’s Energeek do the hardwork for you.</span>
        </div> 
        <button onClick={startBtnHandler} type='submit'>Start Now</button>
        </div>      
    </div>)
}

export default BottomContainer;