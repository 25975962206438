import React, { useState,useEffect } from 'react';
import styles from '../adminPage.module.css'
import userSide from '../../images/userSide.png'
import settingsIcon from '../../images/settings.png'
import dashboardIcon from '../../images/dashboard.png'
import quoteIcon from '../../images/quoteIcon.png'
import SettingsPage from '../settingsPage/settingsPage';
import DashboardPage from '../dashboardPage/dashboardPage';
import QuotationPage from '../quotationPage/quotationPage';
import UsersPage from '../usersPage/usersPage';

const BottomContainer = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className={styles.bottomCont} >
      <div className={styles.sideBarCont} >
        <div
          onClick={() => handleTabClick(1)}
          style={{ cursor: 'pointer', padding: '10px', background: activeTab === 1 ? '#ddd' : '#fff' }}
        >
          <img src={quoteIcon} />
          {windowWidth >=440 && <span style={{color: activeTab === 1 ? '#36B14D' : '#364153'}} >Dashboard</span>}
        </div>
        <div
          onClick={() => handleTabClick(2)}
          style={{ cursor: 'pointer', padding: '10px', background: activeTab === 2 ? '#ddd' : '#fff' }}
        >
          <img src={quoteIcon} />
          {windowWidth >=440 &&<span style={{color: activeTab === 2 ? '#36B14D' : '#364153'}} >Quotation</span>}
        </div>
        <div
          onClick={() => handleTabClick(3)}
          style={{ cursor: 'pointer', padding: '10px', background: activeTab === 3 ? '#ddd' : '#fff' }}
        >
          <img src={userSide} />
          {windowWidth >=440 &&<span style={{color: activeTab === 3 ? '#36B14D' : '#364153'}} >User</span>}
        </div>
        <div
          onClick={() => handleTabClick(4)}
          style={{ cursor: 'pointer', padding: '10px', background: activeTab === 4 ? '#ddd' : '#fff' }}
        >
          <img src={settingsIcon} />
          {windowWidth >=440 &&<span style={{color: activeTab === 4 ? '#36B14D' : '#364153'}} >Settings</span>}
        </div>
      </div>
      <div className={styles.rightCont}>
        {activeTab === 1 && <DashboardPage/>}
        {activeTab === 2 && <QuotationPage/>}
        {activeTab === 3 && <UsersPage/>}
        {activeTab === 4 && <SettingsPage/> }
      </div>
    </div>
  );
};

export default BottomContainer;
