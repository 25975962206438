import styles from './homePage.module.css';
import HeaderContainer from './components/headerContainer';
import RectangleImage from '../images/Rectangle.png'
import BottomContainer from './components/bottomContainer';

function HomePage(props){
    return(
    <div className={styles.homePageContainer}>
        <HeaderContainer updateTkn={props.updateTkn} />
        <div className={styles.imgContainer}>
            <img src={RectangleImage} />
        </div>
        <BottomContainer setHomeTypePg={props.setHomeTypePg}/>
    </div>
    )
}

export default HomePage;