import {config} from "./config"
import axios from "axios";

const apiurl = config.apiurl;

// export const callPostApiSevices=(url,data,successCallBack,errorCallBack)=>{
//     if(localStorage.token){
//         const token = localStorage.getItem('token');
//         axios.post(`${apiurl}${url}`,data,
//         {
//             headers: {Authorization:token && `Bearer ${token}`}
//         },
//         ).then(successCallBack).catch(errorCallBack);
//     }
// }

export const loginApiSevices=(data,successCallBack,errorCallBack)=>{
        axios.post(`${apiurl}/user/login`,data,
        ).then(successCallBack).catch(errorCallBack);
}

export const signupApiSevices=(data,successCallBack,errorCallBack)=>{
    axios.post(`${apiurl}/user/signup`,data,
    ).then(successCallBack).catch(errorCallBack);
}

export const callGetApiSevices=(url,successCallBack,errorCallBack)=>{
    axios.get(`${apiurl}${url}`).then(successCallBack).catch(errorCallBack);
}

export const callPostApiServices=(url,data,successCallBack,errorCallBack)=>{
    axios.post(`${apiurl}${url}`,data).then(successCallBack).catch(errorCallBack);
}

export const callPatchApiSevices=(url,data,successCallBack,errorCallBack)=>{
    axios.patch(`${apiurl}${url}`,data).then(successCallBack).catch(errorCallBack);
}

export const callDeleteApiSevices=(url,successCallBack,errorCallBack)=>{
    axios.delete(`${apiurl}${url}`).then(successCallBack).catch(errorCallBack);
}
