import React, { useEffect,useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTable } from 'react-table';

const PartPriceTable = ({isEditing,partPriceData,updatePartPriceData}) => {

  const [updatedPriceData, setUpdatedPriceData] = useState(partPriceData);
  const[updatedRowId,setUpdatedRowId] = useState([]);


  const data = React.useMemo(() => updatedPriceData, [updatedPriceData]);

  const handleCellChange = (id,rowIndex, columnId, value) => {

    if (!updatedRowId.includes(id)) {
      // If not, push it into the array
      setUpdatedRowId(prevIds => [...prevIds, id]);
    }
    // Create a copy of the current data
    const newData = [...updatedPriceData];
    // Update the specific cell value in the copied data
    newData[rowIndex][columnId] = value;
    // Update the state with the new data
    setUpdatedPriceData(newData);
  };

  useEffect(()=>{
    updatePartPriceData(updatedRowId,updatedPriceData)
  },[updatedRowId,updatedPriceData])


  useEffect(()=>{setUpdatedPriceData(partPriceData)},[partPriceData]);


  // Column definitions
  const columns = React.useMemo(
    () => [
      { Header: 'Product', accessor: 'productName',width: 100 },
      { Header: 'Brand', accessor: 'brand',width: 100 },
      { Header: 'Model', accessor: 'model',width: 100 },
      { Header: 'Description', accessor: 'description',width: 250 },
      { Header: 'UOM', accessor: 'UOM',width:100 },
      { Header: 'Price Per Unit', accessor: 'price',width: 80 },
    ],
    []
  );

  // Create an instance of the useTable hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <table {...getTableProps()} style={{ width: '100%',borderSpacing:0 , backgroundColor:'#FFF'}}>
      <thead >
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={{width: column.width, paddingLeft: '5px', borderBottom:'1px solid #777', textAlign: 'left',paddingTop:'8px',paddingBottom:'8px',fontSize:'12px',fontWeight:'600',color:'#333',  }} >{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row,rowIndex) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td style={{ paddingRight: cell.column.id === 'price' ? '30px' : '', whiteSpace: cell.column.id === 'productName' ? 'nowrap':'', width:cell.column.width, paddingLeft: '6px',  borderBottom:'1px solid #777', textAlign: 'left', textAlign: cell.column.id === 'price' ? 'right' : 'left',paddingTop:'8px',paddingBottom:'8px',fontSize:'12px',fontWeight:'400',color:'#000' }} {...cell.getCellProps()}>
                  {cell.column.id === 'productName' ? (
                    cell.render('Cell')
                  ) : (
                    isEditing ? (
                      <input
                        type="text"
                        style={{ width: '100%',border:'1px solid #d1d1d1', paddingRight:cell.column.id === 'price' ? '3px' : '0px', paddingLeft:cell.column.id !== 'price' ? '3px' : '0px', textAlign: cell.column.id === 'price' ? 'right' : 'left' }}
                        value={cell.value}
                        onChange={e => {
                          handleCellChange(row.original._id,rowIndex, cell.column.id, e.target.value);
                        }}
                      />
                    ) : (
                      cell.render('Cell')
                    )
                  )}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PartPriceTable;
