import React from 'react';
import { useState,useEffect } from 'react';
import { useTable } from 'react-table';
import rowPlus from '../../images/rowPlus.png'
import DeleteIcon from '../../images/bin.png'
import Modal from '../../adminPage/settingsPage/components/modal';
import CommanModal from '../modal/commonModal';
import { callDeleteApiSevices } from '../../webServices/api';

const PartCountTable = ({isEditing,partCountData, selectedPriority,selectedProperty,setSelectedPriority, fetchData,updatePartCountData}) => {

  const [updatedCountData, setUpdatedCountData] = useState(partCountData);
  const[updatedRowId,setUpdatedRowId] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteRowModal, setDeleteRowModal] = useState(false);
  const [deleteRowId,setDeleteRowId] = useState();

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const data = React.useMemo(
    () => updatedCountData.length > 0 ? updatedCountData :
    [
      {
        roomCount:'',
        roomData:{
          "Smart Home Hub":'',
          "Digital Door Lock":'',
          "IP Camera":'',
          "1G Smart Switch":'No',
          "2G Smart Switch":'data',
          "3G Smart Switch":'found',
          "Contact Sensor":'',
          "Motion Sensor":'',
          "Remote Switch":'',
          "Human Presence Sensor":'',
          "Smoke Detector":'',
          "Air Con Controller (IR)":''
        }
      }
    ],
    [updatedCountData]
  );

  // console.log(updatedCountData);

  const handleCellChange = (id,rowIndex, columnId, value) => {

    if (!updatedRowId.includes(id)) {
      setUpdatedRowId(prevIds => [...prevIds, id]);
    }
    
    const newData = [...updatedCountData];
    newData[rowIndex].roomData[columnId] = parseInt(value);
    setUpdatedCountData(newData);
  };

  useEffect(()=>{
    if(isEditing){
      updatePartCountData(updatedRowId,updatedCountData);
    }else if(!isEditing && updatedRowId.length > 0){
      setUpdatedRowId([]);
      setUpdatedCountData(partCountData);
    }
   
  },[updatedRowId,updatedCountData])

  useEffect(()=>{setUpdatedCountData(partCountData)},[partCountData]);

  const columns = React.useMemo(
    () => [
      { Header: 'Room Count', accessor: 'roomCount',width:150 },
      { Header: 'Smart Home Hub', accessor: 'roomData.Smart Home Hub',width:200 },
      { Header: 'Digital Door Lock', accessor: 'roomData.Digital Door Lock',width:200 },
      { Header: 'IP Camera', accessor: 'roomData.IP Camera',width:80 },
      { Header: 'Air Con Controller (IR)', accessor: 'roomData.Air Con Controller (IR)',width:150 },
      { Header: '1G Smart Switch', accessor: 'roomData.1G Smart Switch',width:200 },
      { Header: '2G Smart Switch', accessor: 'roomData.2G Smart Switch',width:200 },
      { Header: '3G Smart Switch', accessor: 'roomData.3G Smart Switch',width:200 },
      { Header: 'Contact Sensor', accessor: 'roomData.Contact Sensor',width:150 },
      { Header: 'Motion Sensor', accessor: 'roomData.Motion Sensor',width:150 },
      { Header: 'Remote Switch', accessor: 'roomData.Remote Switch',width:150 },
      { Header: 'Human Presence Sensor', accessor: 'roomData.Human Presence Sensor',width:200 },
      { Header: 'Smoke Detector', accessor: 'roomData.Smoke Detector',width:100 },
      { Header: '', accessor: 'emptyColumn', width: 100 },
    ],
    []
  );

  function deleteRowModalHandler(rowId){
    setDeleteRowModal(!deleteRowModal);
    setDeleteRowId(rowId);
  }

  function deleteRowHandler(){

    callDeleteApiSevices(`/part-counts/${deleteRowId}`,
    (response)=>{
      if(response.status === 200){
      console.log(response);
      deleteRowModalHandler(null);
      fetchData();
      
      }
    },
    (err)=>{
      alert(err)
    }
    )
  }
  

  // Create an instance of the useTable hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <>
      <Modal fetchData={fetchData} selectedPriority={selectedPriority} selectedProperty={selectedProperty} showModal={showModal} closeModal={closeModal} />
      { deleteRowModal && <CommanModal handler={deleteRowHandler} message={'Are you sure you want to delete this row ?'} deleteUser={deleteRowModalHandler} />}
    <table {...getTableProps()} style={{ width: '100%',borderSpacing:0 , backgroundColor:'#Fff'}}>
      <thead >
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={{textAlign: 'right', paddingRight:'5px',width: column.id === 'emptyColumn' ? '100px' : '35px', borderBottom:'1px solid #777',fontFamily:'Raleway',paddingTop:'8px',paddingBottom:'8px',fontSize:'12px',fontWeight:'600',color:'#333' }} >{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row, rowIndex) => {
  prepareRow(row);
  return (
    <tr {...row.getRowProps()} key={rowIndex}

    >
      {row.cells.map((cell, cellIndex) => (
  <td
    style={{
      width: cell.column.width,
      paddingRight: '10px',
      borderBottom: '1px solid #777',
      textAlign: 'right',
      paddingTop: '8px',
      paddingBottom: '8px',
      fontSize: '12px',
      fontWeight: '400',
      color: '#000',
    }}
    key={cellIndex}
    {...cell.getCellProps()}
  >
    {cell.column.id === 'emptyColumn' && isEditing ?         
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          {cellIndex === row.cells.length - 1 ?  <img src={DeleteIcon} alt="Add Row" style={{width:'1rem',height:'1.3rem',cursor:'pointer'}} onClick={()=>deleteRowModalHandler(row.original._id)} /> : cell.render('Cell')}
        </div> : (
      isEditing && cell.column.id !== 'roomCount' ? (
        <input
          type="text"
          style={{ width: '100%', border: '1px solid #d1d1d1', textAlign: 'right', paddingRight:'3px' }}
          defaultValue={String(row.original.roomData[cell.column.Header])}
          onChange={(e) => {
            handleCellChange(row.original._id, rowIndex, cell.column.Header, e.target.value);
          }}
        />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          {cell.render('Cell')}
        </div>
      )
    )}
    {/* {(cellIndex === row.cells.length - 1 && rowIndex === rows.length - 1 && !isEditing) && (
      <img src={rowPlus} alt="Add Row" style={{ marginLeft: '7px', width: '16px', cursor: 'pointer' }} onClick={openModal} />
    )} */}
  </td>
))}
</tr>   
  );
})}

{!isEditing && (
    <tr>
    {columns.map((column, columnIndex) => (
      <td
        key={columnIndex}
        style={{
          width: column.width,
          paddingRight: '10px',
          borderBottom: '1px solid #777',
          textAlign: 'right',
          paddingTop: '8px',
          paddingBottom: '8px',
          fontSize: '12px',
          fontWeight: '400',
          color: '#000',
        }}
      >
        {columnIndex === columns.length - 8 && (
          <img src={rowPlus} alt="Add Row" style={{ marginLeft: '7px', width: '24px', height:'24px', cursor: 'pointer', alignSelf: 'center' }} onClick={openModal} />
        )}
      </td>
    ))}
  </tr>
            )}

    </tbody>
    </table>
    </>
  );
};

export default PartCountTable;
