import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const DragSlider = (props) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const[progress,setProgress]= useState(localStorage.getItem('progress') || 0)
  
    const handleDrag = (e, ui) => {
      const { x, y } = ui;
      setPosition({ x, y });
    };

    useEffect(() => {
      
      const storedProgress = localStorage.getItem('progress');
      console.log(parseInt(storedProgress))
      if (storedProgress) {
          setProgress(storedProgress);
      }
  }, [localStorage.getItem('progress')]);



    const sliderStyle = {
        trackStyle: {
          backgroundColor: '#36B14D',
          height:'2px'
        },
        handleStyle: {
          borderColor: '#36B14D',
          backgroundColor: '#36B14D',
          opacity:1,
          height:'12px',
          width:'12px'
        },
        railStyle: {
          backgroundColor: 'lightGreen',
          height:'2px'
        },
      };
  
    return (
      <Draggable position={position} onDrag={handleDrag}>
        <div>
          <Slider
            min={0}
            max={100}
            value={progress}
            {...sliderStyle}
          />
        </div>
      </Draggable>
    );
  };
  
  export default DragSlider;
  