import styles from './contactUs.module.css';
import ContactUsImg from '../images/contactUs.png'
import Logo from '../images/logo.png'
import BtnStyles from '../main.module.css'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import MapMarker from '../images/mapMarker.svg'
import EmailIcon from '../images/emailIcon.svg'
import WebIcon from "../images/webIcon.svg"
import axios from 'axios';
import { callPostApiServices } from '../webServices/api';
import CommanModal from '../components/modal/commonModal';


function ContactUs(props){

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [mblNo,setMblNo] = useState('');
    const [description,setDescription] = useState('');
    const [errMsg,setErrMsg] = useState('');
    const [sending,setSending] = useState(false);
    const[msgSentModal,setMsgSentModal] = useState(false);

    const navigate = useNavigate();

    function msgSentmodalHandler(){
      setMsgSentModal(!msgSentModal);
      navigate('/viewQuotation')
    }

    const handleSubmit =async(e)=>{
        e.preventDefault();
        if(name && email && mblNo && description){
          setErrMsg('')
          setSending(true)
          callPostApiServices(`/contact-us`,
          {
            name:name,
            email:email,
            phone_number:mblNo,
            description:description
          },
          (response)=>{
            if(response.status === 201){
              setName('');
              setEmail('');
              setMblNo('');
              setDescription('');
              setSending(false);
              setMsgSentModal(true);
            }
          },
          (err)=>{
            alert(err);
        }
          )
        }else{
          setErrMsg('Please fill all the details')
        }
    }

    function handleNameChange(e){
        setName(e.target.value)
    }

    function handleEmailChange(e){
        setEmail(e.target.value)
    }

    function handleMblNoChange(e){
        setMblNo(e.target.value)
    }
 
    function handleDescChange(e){
        setDescription(e.target.value)
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      useEffect(()=>{
        if(name && email && mblNo && description){
          setErrMsg('');
        }
      },[name,email,mblNo,description])


    return(
    <>
    {msgSentModal && <CommanModal message={'Message sent successfully'} onHide={msgSentmodalHandler} />}
    <div className={styles.contactUsContainer}>
        <div className={styles.leftCont}>
            <div className={styles.leftContSub}>
            <img src={Logo} onClick={()=>navigate('/viewQuotation')} />
            <div className={styles.popup} id="popup">     
                <span className={styles.heading} >Contact Us</span>
                <form onSubmit={handleSubmit}>
                  <div className={styles.errMsg}>
                    <label>First and last name</label>
                    <input
                      spellCheck={false}
                      type="text"
                      id="username"
                      name="username"
                      value={name}
                      onChange={handleNameChange}
                    />

                  </div>
                  <div className={styles.errMsg}>
                    <label>Email address</label>
                    <input
                      spellCheck={false}
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
      
                  </div>

                  <div className={styles.errMsg}>
                    <label>Mobile</label>
                    <input
                      spellCheck={false}
                      type="text"
                      id="mobile"
                      name="mobile"
                      value={mblNo}
                      onChange={handleMblNoChange}
                    />
      
                  </div>

                  <div className={styles.errMsg}>
                  <label>Description</label>
                  <textarea 
                  id="description" 
                  name="description"
                  style={{height:'70px', fontSize:'1.3rem',paddingLeft:'5px'}}
                  value={description}
                  onChange={handleDescChange}
                  />
                  </div>

                  {errMsg && <span style={{color:'red',fontSize:'1.2rem',marginBottom:'0.5rem'}}>{errMsg}</span>}

                  <input  type="submit" value={sending ? 'sending...':'send'} disabled={sending} />
                </form>

                
            <div className={styles.infoCont} >
                <div className={styles.info} >
                    <img src={MapMarker}/>
                    <span>138 Robinson Road, #02-26 Oxley Tower, Singapore 068906</span>
                </div>
                <div className={styles.info} >
                <a href="https://www.energeek.tech/" style={{paddingTop:'0px'}}>
                    <img src={WebIcon}/>
                 </a>   
                    <span>www.energeek.tech</span>
                </div>
                <div className={styles.info} >
                <a href="mailto:hello@energeek.tech" style={{paddingTop:'0px'}}>
                    <img src={EmailIcon} alt="Email Icon"/>
                </a>
                    <span>hello@energeek.tech</span>
                </div>
            </div>

            </div>

            </div>
        </div>

        <div className={styles.imgContainer}>
            <img src={ContactUsImg} />
        </div>
    </div>
    </>
    )
}

export default ContactUs;