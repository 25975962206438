import styles from './settingsPage.module.css'
import { useState } from 'react';
import PartCount from './components/partCount';
import PartPrice from './components/partPrice';

function SettingsPage(){
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
    };

    return(
    <div className={styles.settingsPageCont}>
        <div className={styles.barCont} >
            <div
            onClick={() => handleTabClick(1)}
            style={{ cursor: 'pointer', padding: '10px', borderBottom: activeTab === 1 ? '1.5px solid #36B14D' : ''}}
            >
            <span style={{color:activeTab === 1?'#364153':'rgba(54, 65, 83, 0.60)' }} >Part Count</span>
            </div>

            <div
            onClick={() => handleTabClick(2)}
            style={{ cursor: 'pointer', padding: '10px', borderBottom: activeTab === 2 ? '1.5px solid #36B14D' : '' }}
            >
            <span style={{color:activeTab === 2?'#364153':'rgba(54, 65, 83, 0.60)' }}  >Part Price</span>
            </div>

            

      </div>
      <div className={styles.bottomCont}>
        {activeTab === 1 && <PartCount/>}
        {activeTab === 2 && <PartPrice/>}
      </div>
    </div>
    )
}

export default SettingsPage;