import React from 'react';
import {useState,useEffect } from 'react';
import styles from './commonModal.module.css'
import DeleteUser from '../../images/deleteUser.svg'


const CommanModal = (props)=> {

    function modalHandler(){
      if(props.onHide){
        props.onHide();
      }
        if(props.handler){
            props.handler();
        }
    }

  return (
    <div className={styles.modal} onClick={props.deleteUser?props.deleteUser :props.onHide}>
    <div className={styles.modalContent} >
        <div className={styles.circle}>
          {props.deleteUser && <img src={DeleteUser} />}
        </div>
        <span>{props.message}</span>
        <div className={styles.subMsgCont} >
        { props.subMessage && <span className={styles.subMessage} >{props.subMessage}</span>}
        </div>

      <div className={styles.btnCont}>
     {props.deleteUser && <button className={styles.cancelBtn} onClick={props.deleteUser}  type='submit'>cancel</button> } 
      <button onClick={modalHandler}  type='submit'>Ok</button>
      </div>
       
   </div>
    </div>
  );
};

export default CommanModal;
