import styles from "./dashboardPage.module.css";
import QuoteTotal from "../../images/quoteTotal.svg";
import TotalAmount from "../../images/Totalamount.svg";
import TotalUsers from "../../images/TotalUsers.svg";
import SimBarChart from "../../components/charts/barChart";
import { callGetApiSevices } from "../../webServices/api";
import { useEffect } from "react";
import { useState } from "react";

function DashboardPage() {
  const [dashboardPageData, setDashboardPageData] = useState({});

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const minus11MonthsDate = new Date(currentDate);
  minus11MonthsDate.setMonth(minus11MonthsDate.getMonth() - 11);
  minus11MonthsDate.setDate(1);

  const formattedMinus11MonthsDate = minus11MonthsDate.toLocaleDateString(
    "en-US",
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    }
  );

  useEffect(() => {
    callGetApiSevices(
      `/admin-dashboard`,
      (response) => {
        if (response.status === 200) {
          setDashboardPageData(response.data);
        } else {
          alert("something went wrong");
        }
      },
      (err) => {
        alert(err);
      }
    );
  }, []);

  console.log(dashboardPageData);

  return (
    <div className={styles.dashboardPageCont}>
      <div className={styles.headingCont}>
        <span>Dashboard</span>
      </div>

      <div className={styles.cardsCont}>
        <div className={styles.card}>
          <div className={styles.cardTop}>
            <div className={styles.cardTopLeft}>
              <span>{dashboardPageData?.totalQuotations || 0}</span>
              <span>Total Quotations Generated</span>
            </div>
            <img src={QuoteTotal} />
          </div>

          <div className={styles.cardBottom}>
            <div className={styles.cardBottomSub}>
              <span
                style={{
                  color:
                    dashboardPageData?.quotationPercentage < 0
                      ? "#FD5942"
                      : dashboardPageData?.quotationPercentage > 0
                      ? "#30C07D"
                      : "black",
                }}
              >{`${
                dashboardPageData?.quotationPercentage < 0
                  ? "↓"
                  : dashboardPageData?.quotationPercentage > 0
                  ? "↑"
                  : ""
              } ${dashboardPageData?.quotationPercentage || 0}%`}</span>
              <span>Since Last Month</span>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardTop}>
            <div className={styles.cardTopLeft}>
              <span>
                ${" "}
                {(dashboardPageData?.totalQuotedAmount &&
                  Number(dashboardPageData?.totalQuotedAmount).toLocaleString(
                    "en-US"
                  )) ||
                  0}
              </span>
              <span>Total Quoted Amount</span>
            </div>
            <img src={TotalAmount} />
          </div>

          <div className={styles.cardBottom}>
            <div className={styles.cardBottomSub}>
              <span
                style={{
                  color:
                    dashboardPageData?.quotedAmountPercentage < 0
                      ? "#FD5942"
                      : dashboardPageData?.quotedAmountPercentage > 0
                      ? "#30C07D"
                      : "black",
                }}
              >{`${
                dashboardPageData?.quotedAmountPercentage < 0
                  ? "↓"
                  : dashboardPageData?.quotedAmountPercentage > 0
                  ? "↑"
                  : ""
              } ${dashboardPageData?.quotedAmountPercentage || 0}%`}</span>
              <span>Since Last Month</span>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardTop}>
            <div className={styles.cardTopLeft}>
              <span>{dashboardPageData?.totalUsers || 0}</span>
              <span>Total Users</span>
            </div>
            <img src={TotalUsers} />
          </div>

          <div className={styles.cardBottom}>
            <div className={styles.cardBottomSub}>
              <span
                style={{
                  color:
                    dashboardPageData?.userPercentage < 0
                      ? "#FD5942"
                      : dashboardPageData?.userPercentage > 0
                      ? "#30C07D"
                      : "black",
                }}
              >{`${
                dashboardPageData?.userPercentage < 0
                  ? "↓"
                  : dashboardPageData?.userPercentage > 0
                  ? "↑"
                  : ""
              } ${dashboardPageData?.userPercentage || 0}%`}</span>
              <span>Since Last Month</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bottomCont}>
        <span>Number of Quotation</span>
        <span>
          {formattedMinus11MonthsDate} - {formattedDate}
        </span>
        <div className={styles.chartCont}>
          <SimBarChart dashboardPageData={dashboardPageData} />
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
