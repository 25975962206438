import styles from '../homePage.module.css'
import Logo from '../../images/logo.png'
import LoginPage from '../../components/loginPage/loginPage';
import { useState } from 'react';
import SignUp from '../../components/signUp/signUp';

function HeaderContainer(props){
    const[showLoginPage, setShowLoginPage] = useState(false);
    const[signUpPage, setSignUpPage] = useState(false);
   

    function signUpFormHandler(){
        setShowLoginPage(!showLoginPage);
        setSignUpPage(!signUpPage);
    }

    function loginFromSignUp(){
        setShowLoginPage(!showLoginPage);
        setSignUpPage(!signUpPage);
    }

    function loginPageHandler(){
        setShowLoginPage(!showLoginPage);
    }

    function signUpFromLogin(){
        setShowLoginPage(!showLoginPage);
        setSignUpPage(!signUpPage);
    }

    function signUpFormOnlyHandler(){
        setSignUpPage(!signUpPage)
    }

    return(
    <>
    {showLoginPage && <LoginPage updateTkn={props.updateTkn} signUpFormHandler={signUpFormHandler} loginPageHandler={loginPageHandler}  setShowLoginPage={setShowLoginPage}/>}
    {signUpPage && <SignUp signUpFromLogin={signUpFromLogin} loginFromSignUp={loginFromSignUp}  setSignUpPage={setSignUpPage}/> }
       <div className={styles.headerContainer}>
        <img src={Logo} />
        <div className={styles.headerBtnCont}>
        <button onClick={loginPageHandler} >Log in</button>
        <button onClick={signUpFormOnlyHandler}>Sign Up</button>
        </div>
    </div> 
    </>

    )
}

export default HeaderContainer;