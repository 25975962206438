import React from 'react';
import {useState,useEffect } from 'react';
import styles from './profileModal.module.css'
import projectNameIcon from '../../images/projectNameIcon.svg'


const ProfileModal = (props)=> {

    const[projectName, setProjectName] = useState(`prj-${new Date().getTime()}`);
    const[err,setErr] = useState(false);

    useEffect(()=>{
        localStorage.setItem('projectName',projectName);
    },[projectName])

    function handleNameClick(){
        setProjectName('');
    }

    function handleNameChange(e){
        setProjectName(e.target.value);
    }

    function proceedToQuotationHandler(){
        if(!projectName){
            return;
        }
        props.quotationViewhandler();
    }

  return (
    <div className={styles.modal}>
    <div className={styles.modalContent} >
        <div className={styles.circle}>
            <img src={projectNameIcon} />
        </div>
        <span>Project Name</span>
            <input
                      spellCheck={false}
                      type="text"
                      value={projectName}
                      onChange={handleNameChange}
                      onClick={handleNameClick}
                    />
      
        <button onClick={proceedToQuotationHandler}  type='submit'>Proceed to Quotation</button>
   </div>
    </div>
  );
};

export default ProfileModal;
