import styles from './homeTypePage.module.css'
import mainStyles from '../main.module.css'
import HomeType from '../images/hometype.png'
import { useNavigate } from "react-router-dom";
import DragSlider from '../components/dragSlider/dragSlider'
import PropertyTypePage from '../propertyType/propertyTypePage';
import { useState } from 'react';

function HomeTypePage(props){

    const[clickedBtn, setClickedBtn] = useState(null);
    const navigate = useNavigate();
    const[progress,setProgress]=useState(0);
    const [homeType, setHomeType] = useState(localStorage.getItem('homeType'));

    function homeTypeHandler(type){
        localStorage.setItem('homeType',type);
        setHomeType(type)
    }

    function homeTypeNextHandler(){
        props.setPropertyTypePg(true);
        localStorage.setItem('progress',30);
        localStorage.getItem('token') ||props.token?navigate('/property-type-auth'):  navigate('/property-type')

    }
    
    
return(<div className={styles.homeTypePageContainer}>
       <div className={styles.imgContainer}>
            <img src={HomeType} />
        </div>
        <div className={styles.rightCont}>
        <div className={styles.rightContSub}>
            <div style={{textAlign:'center'}}>
            <span>Please choose the best to describe your home</span>
            </div> 
            
            <div>
                <button onClick={()=>homeTypeHandler('New')} style={{backgroundColor:homeType === 'New'?'#36B14D':'', color:homeType === 'New'?'#fff':''}} className={mainStyles.outlinedBtn} >New</button>
                <button onClick={()=>homeTypeHandler('Existing')} style={{backgroundColor:homeType === 'Existing'?'#36B14D':'', color:homeType === 'Existing'?'#fff':''}} className={mainStyles.outlinedBtn} >Existing</button>
            </div>
            <div>
                <DragSlider />
            </div>
            
            <div>
                <button style={{backgroundColor:homeType?'#36B14D':'', color:homeType?'#fff':''}} disabled={!homeType} className={mainStyles.outlinedBtnBottom} onClick={homeTypeNextHandler} >Next</button>
            </div>
            </div>
        </div>
</div>)
}

export default HomeTypePage;