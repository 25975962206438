import styles from '../viewQuotationPage.module.css'
import Logo from '../../images/logo.png'
import User from '../../images/user.png'
import { useState } from 'react';
import EmailIcon from '../../images/emailIcon.svg'
import { useNavigate } from 'react-router-dom';

function HeaderContainer(){

    const [showLogout, setShowLogout] = useState(false);
    const navigate = useNavigate();

    const openLogout = ()=>{
        setShowLogout(!showLogout);
    }

    const handleLogout = ()=>{
        localStorage.clear();
        setShowLogout(false);
        window.location.reload();
    }

    return(<div className={styles.headerContainer}>
        <img src={Logo} />
        <div className={styles.headerBtnCont}>
        <img className={styles.user} src={EmailIcon} onClick={()=>navigate('/contact-us')} />
        <img className={styles.user} src={User} onClick={openLogout} />
        {showLogout && (
        <div className={styles.logoutPopup}>
          <p onClick={handleLogout}>Logout</p>
        </div>
      )}
        </div>
    </div>)
}

export default HeaderContainer;