import React from 'react';
import {useState,useEffect } from 'react';
import styles from './forgotPassword.module.css'
import { useNavigate } from 'react-router-dom';
import { callPostApiServices } from '../../webServices/api';


const ForgotPassword = (props)=> {

    const[email, setEmail] = useState();
    const [emailError, setEmailError] = useState("");
    const[err,setErr] = useState(false);
    const[reqSent,setReqSent] = useState(false);
    const[reqSending, setReqSending] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    function handleEmailChange(e){
        const inputValue = e.target.value;
        setEmail(inputValue);
        if (!validateEmail(inputValue)) {
            setEmailError("Please enter a valid email");
          } else {
            setEmailError("");
          }
    }

    function closeModalHandler(e){
        console.log(e.target.getAttribute('value'))
        if(e.target.getAttribute('value') === 'overlay' || e.target.getAttribute('value') === 'okBtn'){
        setEmail('');
        setEmailError('')
        props.onHide(e);
        }
    }

    function resetPasswordHandler(e){
        console.log(2)
        if(email){
            setReqSending(true);
        callPostApiServices(`/user/forgot-password`,
        {
            email:email
        },
        (response)=>{
            if(response.status === 201)
            {
                console.log(response)
                setReqSent(true);
                setReqSending(false)
            }
        },
        (err)=>{

            setEmailError('Email is not registered. Please check the Email');
        }
        )
    }
    }

 function linkSentHandler(e){
    console.log(1)
    closeModalHandler(e)
    setReqSent(false);
 }


  return (
    <div className={styles.modal} value={'overlay'} onClick={closeModalHandler}>
    <div className={styles.modalContent} >
        <div className={styles.circle}></div>
        { !reqSent &&
        <>
        <span>Request Reset</span>
        <div className={styles.inputCont}>
            <input
                    placeholder='Enter your Email'
                      spellCheck={false}
                      type="text"
                      value={email}
                      onChange={handleEmailChange}
            />
            {emailError && <span className={styles.errMsgSpan} >{emailError}</span>}
        </div>    
        <button className={styles.reqRes} disabled={emailError || reqSending} onClick={resetPasswordHandler}  type='submit' >{ reqSending ?'Sending Request...' :'Request Reset'}</button>
        </>
        }

        {reqSent && 
        <>
        <span>Reset Link Sent Successfully</span>
        <button className={styles.reqRess} value={'okBtn'} onClick={linkSentHandler}  type='submit' >Ok</button>
        </>
        }
   </div>
    </div>
  );
};

export default ForgotPassword;
