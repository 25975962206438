import styles from './adminPage.module.css'
import HeaderContainer from './components/headerContainer';
import BottomContainer from './components/bottomContainer';

function AdminPage(){
    return(
    <div className={styles.adminPageContainer} >
            <HeaderContainer />
            <BottomContainer />
    </div>
    )
}

export default AdminPage;