import styles from './priorityPage.module.css';
import PriorityTypeMain from '../images/priorityTypeMain.png'
import BtnStyles from '../main.module.css'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import DragSlider from '../components/dragSlider/dragSlider';
import { callPostApiServices } from '../webServices/api';
import PriorityTypeOne from '../images/priorityTypeOne.png';
import PriorityTypeTwo from '../images/priorityTypeTwo.png';
import PriorityTypeThree from '../images/priorityTypeThree.png';
import PriorityTypeFour from '../images/priorityTypeFour.png';
import PriorityTypeFive from '../images/priorityTypeFive.png';

function PriorityPage(props){

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const[clickedBtn, setClickedBtn] = useState(null);
    const[priorityType, setPriorityType]= useState(localStorage.getItem('priorityType'));
    const[progress,setProgress]=useState(0);

    const navigate = useNavigate();

    function priorityTypeHandler(type){
        localStorage.setItem('priorityType',type);
        setPriorityType(type)
    }

    function priorityNextHandler(){
        props.setLoaderndQuotPg(true);
        localStorage.setItem('progress',100);
        localStorage.getItem('token') || props.token ?  navigate('/loaderPage-auth') :  navigate('/loaderPage')
        // let hmType = localStorage.getItem('homeType')
        // let propType = localStorage.getItem('propertyType');
        // let rmCount = localStorage.getItem('rooms');
        // let priorType = localStorage.getItem('priorityType');
        // let userId = localStorage.getItem('userId')

        // if(localStorage.getItem('token') || props.token){
        //     callPostApiServices(`/quotations`,
        //     {
        //         projectName : `prj-${new Date().getTime()}`,
        //         rooms :rmCount,
        //         describe : hmType,
        //         propertyType :propType,
        //         priority : priorType,
        //         user: userId
        //     },
        //     (response)=>{
        //         localStorage.getItem('token') || props.token ?  navigate('/loaderPage-auth') :  navigate('/loaderPage')
        //         if(response.status === 200){
        //             console.log(response);

        //             localStorage.setItem('newUserQuotationDetails',JSON.stringify(response.data));
        //             setTimeout(()=>{
        //               localStorage.getItem('token') || props.token ?  navigate('/quotationPage-auth'):navigate('/quotationPage')
        //             },3000)

        //         }else{
        //             alert('something went wrong')
        //         }
        //     },
        //     (err)=>{
        //         alert(err);
        //     }
        //     )
        // }else if(!localStorage.getItem('token') || !props.token){
        //     localStorage.getItem('token') || props.token ?  navigate('/loaderPage-auth') :  navigate('/loaderPage')
        //     callPostApiServices(`/quotations`,
        //     {
        //         projectName : `prj-${new Date().getTime()}`,
        //         rooms :rmCount,
        //         describe : hmType,
        //         propertyType :propType,
        //         priority : priorType,
        //     },
        //     (response)=>{
        //         if(response.status === 200){
        //             localStorage.setItem('newUserQuotationDetails',JSON.stringify(response.data));
                 

        //             setTimeout(()=>{
        //               localStorage.getItem('token') || props.token ?  navigate('/quotationPage-auth'):navigate('/quotationPage')
        //             },3000)
        //         }else{
        //             alert('something went wrong')
        //         }
        //     },
        //     (err)=>{
        //         alert(err);
        //     }
        //     )
        // }


    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return(<div className={styles.priorityPageContainer}>
        <div className={styles.leftCont}>
            <div className={styles.leftContSub}>
                <div>
                    <span>What’s your priority?</span>
                    
                </div>

                <div className={styles.imgnBtnContainer}>
                    <div>
                        <div>
                            <img src={PriorityTypeOne} onClick={()=>priorityTypeHandler('Lifestyle')} />
                            <button style={{backgroundColor:priorityType === 'Lifestyle'?'#36B14D':'', color:priorityType === 'Lifestyle'?'#fff':''}} onClick={()=>priorityTypeHandler('Lifestyle')} className={BtnStyles.outlinedBtn} >Lifestyle</button>
                        </div>
                        <div>
                            <img src={PriorityTypeTwo} onClick={()=>priorityTypeHandler('Eldercare')} />
                            <button  style={{backgroundColor:priorityType === 'Eldercare'?'#36B14D':'', color:priorityType === 'Eldercare'?'#fff':''}} onClick={()=>priorityTypeHandler('Eldercare')} className={BtnStyles.outlinedBtn} >Eldercare</button>
                        </div>
                        <div>
                            <img src={PriorityTypeThree} onClick={()=>priorityTypeHandler('Childcare')} />
                            <button style={{backgroundColor:priorityType === 'Childcare'?'#36B14D':'', color:priorityType === 'Childcare'?'#fff':''}} onClick={()=>priorityTypeHandler('Childcare')} className={BtnStyles.outlinedBtn} >Childcare</button>
                        </div>
                    </div>

                    <div>
                        <div>
                            <img src={PriorityTypeFour} onClick={()=>priorityTypeHandler('Security')} />
                            <button style={{backgroundColor:priorityType === 'Security'?'#36B14D':'', color:priorityType === 'Security'?'#fff':''}} onClick={()=>priorityTypeHandler('Security')} className={BtnStyles.outlinedBtn} >Security</button>
                        </div>
                        <div>
                            <img src={PriorityTypeFive} onClick={()=>priorityTypeHandler('Rental')} />
                            <button style={{backgroundColor:priorityType === 'Rental'?'#36B14D':'', color:priorityType === 'Rental'?'#fff':''}} onClick={()=>priorityTypeHandler('Rental')} className={BtnStyles.outlinedBtn} >Rental</button>
                        </div>
                    </div>
                   
                </div>

{windowWidth<=790?
                <>
                <div className={styles.bottomContainerResponsive} >
                        <div>
                            <DragSlider />
                        </div>
                        <div>
                            <button className={BtnStyles.outlinedBtnBottom} style={{backgroundColor:'#36B14D', color:'#fff'}} onClick={()=>navigate('/property-type')} > Back </button>
                            <button style={{backgroundColor:priorityType?'#36B14D':'', color:priorityType?'#fff':''}} className={BtnStyles.outlinedBtnBottom} disabled={!priorityType} onClick={priorityNextHandler} > Next </button>
                        </div>

                </div>
                </>:

                <>
                <div className={styles.bottomContainer} >
                    <button className={BtnStyles.outlinedBtnBottom} style={{backgroundColor:'#36B14D', color:'#fff'}} onClick={()=>navigate('/property-type')} > Back </button>
                        <div>
                            <DragSlider />
                        </div>
                    <button style={{backgroundColor:priorityType?'#36B14D':'', color:priorityType?'#fff':''}} disabled={!priorityType} className={BtnStyles.outlinedBtnBottom} onClick={priorityNextHandler} > Next </button>
                </div>
                </>
}
            </div>
        </div>

        <div className={styles.imgContainer}>
            <img src={PriorityTypeMain} />
        </div>
    </div>)
}

export default PriorityPage;