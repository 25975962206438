import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import ChartDataModal from "../modal/chartDataModal";
import { callGetApiSevices } from "../../webServices/api";
import styles from "../../adminPage/dashboardPage/dashboardPage.module.css";

const SimBarChart = ({ dashboardPageData }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedMonthText, setSelectedMonthText] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [lastDate, setlastDate] = useState(null);
  const [montlyQuotationTableData, setMontlyQuotationTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalQuotationsCount, setTotalQuotationsCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const itemsPerPage = 10;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentPage(1);
  };

  useEffect(() => {
    // if(dashboardPageData && dashboardPageData.data){
    //   const chartData = Object.entries(dashboardPageData.data).map(([name, value]) => ({ name, value }));
    // }
    setData(dashboardPageData.data);
  }, [dashboardPageData]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const data = [
  //   { name: 'Jan', value: 10 },
  //   { name: 'Feb', value: 20 },
  //   { name: 'Mar', value: 15 },
  //   { name: 'Apr', value: 25 },
  //   { name: 'May', value: 30 },
  //   { name: 'Jun', value: 30 },
  //   { name: 'Apr', value: 25 },
  //   { name: 'Apr', value: 25 },
  //   { name: 'Apr', value: 25 },
  //   { name: 'Apr', value: 25 },
  //   { name: 'Apr', value: 25 },
  //   { name: 'Apr', value: 25 },
  // ];

  const convertMonthNameToNumber = (monthName) => {
    const monthMap = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12,
    };
    return monthMap[monthName];
  };

  function fetchMonthlyQuotationdata(currentYear, month, date, page) {
    setOffset((page - 1) * itemsPerPage);
    callGetApiSevices(
      `/quotations?start=${currentYear}-${month}-01&end=${currentYear}-${month}-${date}&limit=${itemsPerPage}&offset=${
        (page - 1) * itemsPerPage
      }`,
      (response) => {
        if (response.status === 200) {
          console.log(response);
          setMontlyQuotationTableData(response.data.data);
          setTotalQuotationsCount(response.data.count);
          openModal();
        }
      },
      (err) => {
        alert(err?.message || "Server Error");
      }
    );
  }

  const handleBarClick = (data) => {
    setSelectedMonthText(data?.name);
    const selectedMonthValue = convertMonthNameToNumber(data?.name);
    if (!isNaN(selectedMonthValue)) {
      setSelectedMonth(selectedMonthValue);
    }
    const getLastDateOfMonth = (month) => {
      const lastDate = new Date(new Date().getFullYear(), month, 0).getDate();
      return lastDate;
    };

    const lastDate = getLastDateOfMonth(selectedMonthValue);
    setlastDate(lastDate);
    const currentYear = new Date().getFullYear();
    setSelectedYear(currentYear);

    fetchMonthlyQuotationdata(
      data.year,
      selectedMonthValue,
      lastDate,
      currentPage
    );
  };

  useEffect(() => {
    if (currentPage === 1) return;
    fetchMonthlyQuotationdata(
      selectedYear,
      selectedMonth,
      lastDate,
      currentPage
    );
  }, [currentPage]);

  return (
    <>
      {showModal && (
        <ChartDataModal
          offset={offset}
          totalQuotationsCount={totalQuotationsCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          selectedMonth={selectedMonthText}
          selectedYear={selectedYear}
          lastDate={lastDate}
          montlyQuotationTableData={montlyQuotationTableData}
          showModal={showModal}
          closeModal={closeModal}
        />
      )}
      <ResponsiveContainer
        className={styles.responsiveCont}
        width={windowWidth >= 650 ? "105%" : 500}
      >
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" axisLine={false} />
          <YAxis axisLine={false} />
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar
            dataKey="value"
            fill="#36B14D"
            barSize={15}
            radius={[5, 5, 5, 5]}
            style={{ cursor: "pointer" }}
            onClick={handleBarClick}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default SimBarChart;
