import React, { useState } from 'react';
import styles from '../settingsPage.module.css'
import { callPostApiServices } from '../../../webServices/api';

const Modal = ({ showModal, closeModal,selectedPriority,selectedProperty,fetchData  }) => {

const[roomCount,setRoomCount] =useState('');
const[smartHomeHub,setSmartHomeHub] = useState('');
const[digitalDoorLock,setDigitalDoorLock] = useState('');
const[ipCamera,setIpCamera] = useState('');
const[airConController,setAirConController] = useState('');
const[oneGSmart,setOneGSmart] = useState('');
const[twoGSmart,setTwoGSmart] = useState('');
const[threeGSmart,setThreeGSmart] = useState('');
const[contactSensor,setContactSensor] = useState('');
const[motionSensor,setMotionSensor] = useState('');
const[remoteSwitch,setRemoteSwitch] = useState('');
const[humanPresenceSensor,setHumanPresenceSensor] = useState('');
const[smokeDetector,setSmokeDetector] = useState('');

const[errMsg,setErrMsg]=useState(false);

const closeModalHandler = ()=>{
    setRoomCount('');
    setSmartHomeHub('');
    setDigitalDoorLock('');
    setIpCamera('');
    setAirConController('');
    setOneGSmart('');
    setTwoGSmart('');
    setThreeGSmart('');
    setContactSensor('');
    setMotionSensor('');
    setRemoteSwitch('');
    setHumanPresenceSensor('');
    setSmokeDetector('');
    closeModal();
    setErrMsg(false);
}

const addPartPriceHandler =()=>{
    if(roomCount && smartHomeHub && digitalDoorLock && ipCamera && airConController && oneGSmart && twoGSmart && threeGSmart && motionSensor && remoteSwitch && humanPresenceSensor && smokeDetector){
        callPostApiServices(`/part-counts`,
    {
        "propertyType":selectedProperty,
        "priority":selectedPriority,
        "roomCount":roomCount,
        "Smart Home Hub":smartHomeHub,
        "Digital Door Lock":digitalDoorLock,
        "IP Camera":ipCamera,
        "1G Smart Switch":oneGSmart,
        "2G Smart Switch":twoGSmart,
        "3G Smart Switch":threeGSmart,
        "Contact Sensor":contactSensor,    
        "Motion Sensor":motionSensor,
        "Remote Switch":remoteSwitch,
        "Human Presence Sensor":humanPresenceSensor,
        "Smoke Detector":smokeDetector,
        "Air Con Controller (IR)":airConController
    },
    (response)=>{
        if(response.status === 200){
            console.log('updated');
            closeModalHandler();
            fetchData();
        }else{
            alert('error updating part count')
        }
    },
    (err)=>{
        alert(err);
    }
        )
    }else{
        setErrMsg(true);
        setTimeout(()=>{
            setErrMsg(false);
        },5000)
    }

}


  const modalStyle = {
    display: showModal ? 'block' : 'none',
  };

  return (
    <div className={styles.modal} style={modalStyle}>
      <div className={styles.modalContent}>
        <span className={styles.close} onClick={closeModalHandler}>&times;</span>

        <div className={styles.modalContentSub} >
            <div>
                <label>Room Count</label>
                <input
                    type="text"
                    value={roomCount}
                    maxlength = "1"
                    onChange={(e) => {
                        setRoomCount(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>Smart Home Hub</label>
                <input
                    type="text"
                    value={smartHomeHub}
                    onChange={(e) => {
                        setSmartHomeHub(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>Digital Door Lock</label>
                <input
                    type="text"
                    value={digitalDoorLock}
                    onChange={(e) => {
                        setDigitalDoorLock(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>IP Camera</label>
                <input
                    type="text"
                    value={ipCamera}
                    onChange={(e) => {
                        setIpCamera(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>Air Con Controller (IR)</label>
                <input
                    type="text"
                    value={airConController}
                    onChange={(e) => {
                        setAirConController(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>1G Smart Switch</label>
                <input
                    type="text"
                    value={oneGSmart}
                    onChange={(e) => {
                        setOneGSmart(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>2G Smart Switch</label>
                <input
                    type="text"
                    value={twoGSmart}
                    onChange={(e) => {
                        setTwoGSmart(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>3G Smart Switch</label>
                <input
                    type="text"
                    value={threeGSmart}
                    onChange={(e) => {
                        setThreeGSmart(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>Contact Sensor</label>
                <input
                    type="text"
                    value={contactSensor}
                    onChange={(e) => {
                        setContactSensor(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>Motion Sensor</label>
                <input
                    type="text"
                    value={motionSensor}
                    onChange={(e) => {
                        setMotionSensor(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>Remote Switch</label>
                <input
                    type="text"
                    value={remoteSwitch}
                    onChange={(e) => {
                        setRemoteSwitch(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>Human Presence Sensor</label>
                <input
                    type="text"
                    value={humanPresenceSensor}
                    onChange={(e) => {
                        setHumanPresenceSensor(e.target.value)
                    }}
                />
            </div>

            <div>
                <label>Smoke Detector</label>
                <input
                    type="text"
                    value={smokeDetector}
                    onChange={(e) => {
                        setSmokeDetector(e.target.value)
                    }}
                />
            </div>
        </div>

        <div className={styles.modalBottomCont}>
            <div>
            {errMsg && <span className={styles.errMsgModal} >Please fill all the data</span>}
            </div>
            <button className={styles.barContbutton} style={{height:'2.3rem',width:'7rem'}} onClick={addPartPriceHandler} >Save</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
