import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./loginPage.module.css";
import Logo from '../../images/logo.png'
import ForgotPassword from "../modal/forgotPassword";
import { loginApiSevices } from "../../webServices/api";
import { callPostApiServices,callPatchApiSevices } from "../../webServices/api";

const LoginPage = (props) => {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginErr,setLoginErr] = useState(false);
  const [loginErrMsg, setLoginErrMsg] = useState('');
  const [passwordResetMailModal,setPasswordResetMailModal] = useState(false);

  const isiPhone = () => {
    return /iPhone/.test(navigator.userAgent);
  };
  const isUseriPhone = isiPhone();


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    if (!validateEmail(inputValue)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    if (!validatePassword(inputValue)) {
      setPasswordError("Password must be at least 8 characters long");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // navigate('/viewQuotation');
    if (validateEmail(email) && validatePassword(password)) {

      loginApiSevices({
        email:email,
        password:password
        },
        (response) => {
          if (response.status === 200) {
          
            setLoginErr(false);
            setLoginErrMsg('');
              if(localStorage.getItem('newUserQuotationDetails')){
                let newUserDetails = localStorage.getItem('newUserQuotationDetails');
                let newUserObj = JSON.parse(newUserDetails)
                console.log(newUserObj._id)
                callPatchApiSevices(`/quotations/${newUserObj._id}`,
                {
                    user: response.data.id
                },
                (response)=>{
                    if(response.status === 200){
                      window.location.reload();
                    }else{
                        alert('server error')
                    }
                },
                (err)=>{
                    alert(err?.message? err.message :'server error');
                }
                )
              }
            localStorage.clear();
            localStorage.setItem("role",response.data.role);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("userId",response.data.id);
            props.updateTkn(response.data.token);
            navigate('/viewQuotation');
            
          }
        },
        (err) => {
          setLoginErr(true);
          setLoginErrMsg(err.message);
        })
    } else {
      setLoginErr(true);
    }
  };

  function passwordResetMailModalOpen(){
    setPasswordResetMailModal(true);
  }

  function passwordResetMailModalClose(e){
    if(e.target.getAttribute('value') === 'overlay' || e.target.getAttribute('value') === 'okBtn'){
        setPasswordResetMailModal(false);
    }
  }



  return (
    <>
    {passwordResetMailModal && <ForgotPassword onHide={passwordResetMailModalClose} />}
   <div className={styles.overlay} onClick={(e)=>{
    if (e.target.classList.contains(styles.overlay)) {
        props.setShowLoginPage(false);
      }
   }} >
              <div className={styles.popup} id="popup">
                <img src={Logo} />
                <span>Log in</span>
                <span>Log in now to see the full quotation</span>
                <form onSubmit={handleSubmit}>
                  <div className={styles.errMsg}>
                    <label>Your email address</label>
                    <input
                      spellCheck={false}
                      type="text"
                      id="username"
                      name="username"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && <span className={styles.errMsgSpan} >{emailError}</span>}
                  </div>

                  <div className={styles.errMsg}>
                  <label>Your Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {passwordError && <span className={styles.errMsgSpan} >{passwordError}</span>}
                    {loginErr &&
                    <div className={styles.loginErrdiv}>
                      <span className={styles.loginErr} >{loginErrMsg ? loginErrMsg:'Invalid Email or Password'}</span>
                    </div>
                     }
                  </div>

                  <input type="submit" value="Log in" />
                  <a href="#" onClick={passwordResetMailModalOpen} >Forgot Password?</a>
                </form>
                <span>New to Energeek?</span>
                <a href="#" onClick={props.signUpFormHandler}>Sign up now</a >
              </div>
  </div>
  </>
  );
};

export default LoginPage;


