import styles from './quotationPage.module.css'
import { useState,useEffect, useRef } from 'react'
import QuoteTotal from '../../images/quoteTotal.svg'
import SearchIcon from '../../images/searchIcon.svg'
import FilterIcon from '../../images/filterIcon.svg'
import { useReactToPrint } from 'react-to-print';
import { callGetApiSevices } from '../../webServices/api'
import AdminQuotationTable from '../../components/table/adminQuotationTable'
import AdminPdfMiniature from './components/adminPdfMiniature'
import PdfModal from '../../viewQuotationPage/components/PdfModal'

function QuotationPage(){

    const componentRef = useRef();
    const[adminQuotationTableData,setAdminQuotationTableData] = useState([]);
    const[actualAdminQuotationTableData, setActualAdminQuotationTableData] = useState([]);
    const[dashboardPageData,setDashboardPageData] = useState({});
    const[pdfData,setPdfData] = useState();
    const[isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedPriority, setSelectedPriority] = useState('');
    const [selectedProperty, setSelectedProperty] = useState('');
    const[quotationTotal, setQuotationTotal] = useState(0);
    const [searcedText,setSearchedText] = useState(null);
    const[offset,setOffset] = useState(1);
    const itemsPerPage = 10;

    function getQuotations(page){
        setOffset((page - 1)*itemsPerPage);
        if(searcedText){
            callGetApiSevices(`/quotations/?search=${searcedText}&limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`,
            (response)=>{
                if(response.status === 200){
                    setQuotationTotal(response.data.count);
                    setAdminQuotationTableData(response.data.data);
                    setActualAdminQuotationTableData(response.data.data)
                }else{
                    alert('something went wrong')
                }
            },
            (err)=>{
                alert(err);
            }
            )
        }else{
        callGetApiSevices(`/quotations/?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`,
        (response)=>{
            if(response.status === 200){
                setQuotationTotal(response.data.count);
                setAdminQuotationTableData(response.data.data);
                setActualAdminQuotationTableData(response.data.data)
            }else{
                alert('something went wrong')
            }
        },
        (err)=>{
            alert(err);
        }
        )
    }
    }


    useEffect(()=>{

        callGetApiSevices(`/admin-dashboard`,
        (response)=>{
            if(response.status === 200){
                setDashboardPageData(response.data);
            }else{
                alert('something went wrong')
            }
        },
        (err)=>{
            alert(err);
        }
        )
    },[])


    // useEffect(()=>{
    //     if(searcedText){
    //         const searchText = searcedText.toLowerCase();
    //         const filteredUsers = actualAdminQuotationTableData.filter(item =>
    //           item.projectName.toLowerCase().includes(searchText)
    //         );
    //         setAdminQuotationTableData(filteredUsers);
    //     }else{
    //         setAdminQuotationTableData(actualAdminQuotationTableData);
    //     }
    // },[searcedText]

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            // Close the modal after printing
            setIsModalOpen(false);
        },
    });

    const handleModal =()=>{
        setIsModalOpen(!isModalOpen);
    }

    useEffect(() => {
        getQuotations(currentPage);
    }, [currentPage,searcedText]);

    const handlePageChangePrevious = (newPage) => { 
        setCurrentPage(newPage);
    };

    const handlePageChangeNext = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSelectPriorityChange = (event) => {
        setSelectedPriority(event.target.value);
    };
  
      const handleSelectPropertyChange = (event) => {
          setSelectedProperty(event.target.value);
    };

    useEffect(()=>{
        const data = actualAdminQuotationTableData;
        if(adminQuotationTableData.length === 0){
            setAdminQuotationTableData(data);
        }

        

        if(selectedPriority === '' && selectedProperty === ''){
            setAdminQuotationTableData(actualAdminQuotationTableData);
            return;
        }

        const filteredData = data.filter(project => {
            return (
              (selectedPriority === '' || project.priority.toLowerCase() === selectedPriority.toLowerCase()) &&
              (selectedProperty === '' || project.propertyType.toLowerCase() === selectedProperty.toLowerCase())
            );
          });

          setAdminQuotationTableData(filteredData);
    },[selectedPriority,selectedProperty])
  
 
    return(
    <>
    { isModalOpen && <PdfModal onPrs={handlePrint} pdfData={pdfData} onClose={handleModal} ref={componentRef} />}
    <div className={styles.quotationPageCont}>
        <div className={styles.headingCont}>
            <div className={styles.headingContLeft}>
                <span>Quotation</span>

                <div className={styles.card}>
                <div className={styles.cardTop} >
                    <div className={styles.cardTopLeft} >
                        <span>{dashboardPageData?.totalQuotations || 0}</span>
                        <span>Total Quotations Generated</span>
                    </div>
                    <img src={QuoteTotal} />
                </div>

                <div className={styles.cardBottom}>
                    <div className={styles.cardBottomSub} >
                        <span  style={{color:dashboardPageData?.quotationPercentage < 0 ? '#FD5942' : dashboardPageData?.quotationPercentage > 0 ? '#30C07D' : 'black'}} >{`${dashboardPageData?.quotationPercentage < 0 ? '↓' : dashboardPageData?.quotationPercentage > 0 ? '↑' : ''} ${dashboardPageData?.quotationPercentage || 0}%`}</span>
                        <span>Since Last Month</span>
                    </div>

                </div>
                </div>

                <div className={styles.searchnFiltCont} >
                <div className={styles.searchContainer}>
                    <div className={styles.searchBox}>
                        <img src={SearchIcon} alt="Search Icon"/>
                        <input type="text" placeholder="Search" onChange={(e)=>setSearchedText(e.target.value)} />
                    </div>
                </div>

                <div className={styles.filterContainer}>
                    <div className={styles.filterBox}>
                        {/* <img src={FilterIcon} alt="Search Icon"/>
                        <input type="text" placeholder="Filter" /> */}
                    <div>
                    <select id="mySelect" placeholder='Property' value={selectedProperty} onChange={handleSelectPropertyChange}>
                        {/* <option value="">Select...</option> */}
                        <option value="">Property</option>
                        <option value="Condominium">Condominium</option>
                        <option value="HDB">HDB</option>
                        <option value="Landed">Landed</option>
                    </select>
                    </div>

                    <div>
                    <select id="mySelect" placeholder='Priority' value={selectedPriority} onChange={handleSelectPriorityChange}>
                        <option value="">Priority</option>
                        <option value="Lifestyle">Lifestyle</option>
                        <option value="Eldercare">Eldercare</option>
                        <option value="Childcare">Childcare</option>
                        <option value="Security">Security</option>
                        <option value="Rental">Rental</option>
                    </select>
                    </div>
                    </div>
                </div>
                </div>

            </div>

            <div className={styles.headingContRight} >
                <div className={styles.headingContRightSub}  >
                    <div className={styles.headingContRightSubOverlay}>
                        <button className={styles.viewBtn} onClick={handleModal}>View</button>
                    </div>
                {pdfData &&<AdminPdfMiniature pdfData={pdfData}/>}
                </div>      
            </div>
            
        </div>

        <div className={styles.bottomCont} >
        <div className={styles.bottomContTable}>
            <AdminQuotationTable adminQuotationTableData={adminQuotationTableData} setPdfData={setPdfData} offset={offset}/>
        </div> 

        </div>
        <div className={styles.paginationContainer}>
                <button onClick={() => handlePageChangePrevious(currentPage - 1)} disabled={currentPage === 1}>
                Previous
                </button>
                <div className={styles.currentPageCont} >
                    <span>{currentPage}</span>
                </div>
                
                <button onClick={() => handlePageChangeNext(currentPage + 1)} disabled={currentPage*10>=quotationTotal}>
                Next
                </button>
            </div>  
    </div>
    </>
    )
}

export default QuotationPage;