import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./signUp.module.css";
import Logo from '../../images/logo.png'
import { signupApiSevices } from "../../webServices/api";

const SignUp = (props) => {

  const navigate = useNavigate();
  const[name,setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const[nameError,setNameError] = useState("")
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signUpErr,setSignUpErr] = useState(false);
  const [signUpErrMsg,setSignUpErrMsg] = useState('');

  const isiPhone = () => {
    return /iPhone/.test(navigator.userAgent);
  };
  const isUseriPhone = isiPhone();

  const validateName = (name) => {
    return name.length >= 4;
  };


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleNameChange = (e) =>{
    const inputValue = e.target.value;
    setName(inputValue);
    if (!validateName(inputValue)) {
      setNameError("Name must be at least 4 characters long");
    } else {
      setNameError("");
    }
  }

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    if (!validateEmail(inputValue)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    if (!validatePassword(inputValue)) {
      setPasswordError("Password needs 8+ characters, including an uppercase letter, a number, and a special character");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateName(name) && validateEmail(email) && validatePassword(password)) {

      signupApiSevices({
          name:name,
          email:email,
          password:password,
        },
        (response) => {
          if (response.status === 200) {
            setSignUpErr(false);
            setSignUpErrMsg('');
            props.signUpFromLogin();
          }
        },
        (err) => {
          setSignUpErr(true);
          setSignUpErrMsg(err?.message?err.message:'Server Error');
        })
    } else {
      setSignUpErr(true);
      setSignUpErrMsg('Invalid Email or Password');
    }
  };
  //starlly@gmail.com
  //12345678En@



  return (
   <div className={styles.overlay} onClick={(e)=>{
    if (e.target.classList.contains(styles.overlay)) {
        props.setSignUpPage(false);
      }
   }} >
              <div className={styles.popup} id="popup">
                <img src={Logo} />
                <span>Create a free account</span>
                <form onSubmit={handleSubmit}>
                  <div className={styles.errMsg}>
                    <label>First and last name</label>
                    <input
                      spellCheck={false}
                      type="text"
                      id="username"
                      name="username"
                      value={name}
                      onChange={handleNameChange}
                    />
                    {nameError && <span className={styles.errMsgSpan}>{nameError}</span>}
                  </div>
                  <div className={styles.errMsg}>
                    <label>Your email address</label>
                    <input
                      spellCheck={false}
                      type="text"
                      id="username"
                      name="username"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && <span className={styles.errMsgSpan} >{emailError}</span>}
                  </div>

                  <div className={styles.errMsg}>
                  <label>Choose a password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {passwordError && <span className={styles.errMsgSpanPw} >{passwordError}</span>}
                    {signUpErr &&
                    <div className={styles.loginErrdiv}>
                      <span className={styles.loginErr} >{signUpErrMsg}</span>
                    </div>
                     }
                  </div>

                  <input type="submit" value="Signup" />
                </form>
                <span>By signing up you accept our terms of use and policies.</span>
                <div>
                    <span>Already have an account?</span>
                    <a href="#" onClick={props.loginFromSignUp}>Log in</a >
                </div>

              </div>
              </div>
  );
};

export default SignUp;


