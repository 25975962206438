import React, { useEffect } from 'react';
import { useTable } from 'react-table';
import { useState } from 'react';
import { callGetApiSevices } from '../../webServices/api';

const AdminQuotationTable = ({adminQuotationTableData, setPdfData,offset}) => {

  const [selectedRow, setSelectedRow] = useState();
  const [selectedRowData, setSelectedRowData] = useState();

  // const data = React.useMemo(
  //   () => quotationTableData,
  //   [quotationTableData]
  // );

  useEffect(()=>{
    if(adminQuotationTableData.length>0 && setPdfData){
      setSelectedRow(adminQuotationTableData[0]._id)
    }
  },[adminQuotationTableData])


  const data = React.useMemo(() => {
    if(adminQuotationTableData.length === 0){
      return [];
    }
    return adminQuotationTableData.map((item, index) => ({
      ...item,
      serialNumber: (index + 1)+offset,
      createdAtFormatted: new Date(item.createdAt).toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }),
    }));
  }, [adminQuotationTableData]);

// console.log(adminQuotationTableData);

  // Column definitions
  const columns = React.useMemo(
    () => [
      { Header: '#', accessor: 'serialNumber' },
      { Header: 'Date and Time', accessor: 'createdAtFormatted' },
      { Header: 'Project Name', accessor: 'projectName' },
      { Header: 'Quotation No', accessor: 'quotationNumber' },
      { Header: 'Property Type', accessor: 'propertyType' },
      { Header: 'Priority', accessor: 'priority' },
      { Header: 'User', accessor: 'userObject[0].name' },
    ],
    []
  );

  // Create an instance of the useTable hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });


  const handleRowClick = (rowId) => {
    setSelectedRow(rowId == selectedRow ? rowId : rowId);

    callGetApiSevices(`/quotations/${rowId}`,
    (response)=>{
      if(response.status === 200){
        if(setPdfData){
          setPdfData(response.data);
        }
      }else{
          alert('something went wrong')
      }
  },
  (err)=>{
    alert(err);
}
    )
  };

  useEffect(()=>{
    if(adminQuotationTableData.length >0 && selectedRow == adminQuotationTableData[0]._id && setPdfData){
      handleRowClick(selectedRow)
    }
  },[selectedRow])

  return (
    <table {...getTableProps()} style={{ width: '100%',borderSpacing:0 , backgroundColor:'#FFF', borderRadius:'8px'}}>
      <thead >
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={{ paddingLeft: column.Header === '#' ? '5px' : '0',width: column.Header === '#' ? '50px' : 'auto', borderBottom:'1px solid #777', textAlign: 'left',paddingTop:'8px',paddingBottom:'8px',fontSize:'12px',fontWeight:'600',color:'#333' }} >{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          const isRowSelected = row.original._id == selectedRow;
          return (
            <tr {...row.getRowProps()}
            key={row.id}
            onClick={() => handleRowClick(row.original._id)}
            style={{
              background: isRowSelected && setPdfData ? '#36B14D' : 'transparent',
              cursor:'pointer'
            }}
            >
              {row.cells.map(cell => (
                <td style={{ paddingLeft: cell.column.Header === '#' ? '5px' : '0', width: cell.column.Header === '#' ? '50px' : 'auto', borderBottom:'1px solid #777', textAlign: 'left',paddingTop:'8px',paddingBottom:'8px',fontSize:'12px',fontWeight:'400',color:'#000' }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AdminQuotationTable;
