import styles from './loaderPage.module.css'
import ProfileModal from '../components/modal/profileModal';
import { callPostApiServices } from '../webServices/api';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

function LoaderPage(props){

  const[startLoader,setStartLoader] = useState(false);
  const navigate = useNavigate();


  function quotationViewhandler(){
    props.setQuotationPageLoad(true);
    setStartLoader(true);
    let hmType = localStorage.getItem('homeType')
    let propType = localStorage.getItem('propertyType');
    let rmCount = localStorage.getItem('rooms');
    let priorType = localStorage.getItem('priorityType');
    let userId = localStorage.getItem('userId');
    let prjName = localStorage.getItem('projectName');

    if(localStorage.getItem('token') || props.token){
      console.log(1)
      callPostApiServices(`/quotations`,
      {
          projectName : prjName,
          rooms :rmCount,
          describe : hmType,
          propertyType :propType,
          priority : priorType,
          user: userId
      },
      (response)=>{
          localStorage.getItem('token') || props.token ?  navigate('/loaderPage-auth') :  navigate('/loaderPage')
          if(response.status === 200){
              

              localStorage.setItem('newUserQuotationDetails',JSON.stringify(response.data));
              setTimeout(()=>{
                localStorage.getItem('token') || props.token ?  navigate('/quotation-auth'):navigate('/quotation')
              },3000)

          }else{
              alert('something went wrong')
          }
      },
      (err)=>{
          alert(err);
      }
      )
  }
  else if(!localStorage.getItem('token') || !props.token){
    console.log(2)
      localStorage.getItem('token') || props.token ?  navigate('/loaderPage-auth') :  navigate('/loaderPage')
      callPostApiServices(`/quotations`,
      {
          projectName : prjName,
          rooms :rmCount,
          describe : hmType,
          propertyType :propType,
          priority : priorType,
      },
      (response)=>{
          if(response.status === 200){
            console.log(response.data)
              localStorage.setItem('newUserQuotationDetails',JSON.stringify(response.data));
           

              setTimeout(()=>{
                localStorage.getItem('token') || props.token ?  navigate('/quotation-auth'):navigate('/quotation')
              },3000)
          }else{
              alert('something went wrong')
          }
      },
      (err)=>{
          alert(err);
      }
      )
  }


  }

    return(
    <div className={styles.loaderPageContainer} >
      { !startLoader && <ProfileModal quotationViewhandler={quotationViewhandler} />}
    {startLoader && <div className={styles.wrapper}>
    
    <div className={styles.profileMainLoader}>
      <div className={styles.loader}>
        <svg className={styles.circularLoader} viewBox="25 25 50 50" >
          <circle className={styles.loaderPath} cx="50" cy="50" r="20" fill="none" stroke="#70c542" stroke-width="2" />
        </svg>
      </div>
    </div>
        
    </div> }
    </div>
    )
}

export default LoaderPage;