import styles from "./propertyTypePage.module.css";
import BtnStyles from "../main.module.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DragSlider from "../components/dragSlider/dragSlider";
import PropertyTypeOne from "../images/propertyTypeOne.png";
import PropertyTypeTwo from "../images/propertyTypeTwo.png";
import PropertyTypeThree from "../images/propertyTypeThree.png";

function PropertyTypePage(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [clickedBtn, setClickedBtn] = useState(null);
  const [propertyType, setPropertyType] = useState(
    localStorage.getItem("propertyType")
  );
  const [room, setRoom] = useState(localStorage.getItem("rooms"));
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  function propertyTypeHandler(type) {
    localStorage.setItem("propertyType", type);
    setPropertyType(type);
  }

  function noOfRoomsHandler(num) {
    localStorage.setItem("rooms", num);
    setRoom(num);
  }

  function propertyTypeNextHandler() {
    props.setPriorityTypePg(true);
    localStorage.setItem("progress", 70);
    localStorage.getItem("token") || props.token
      ? navigate("/priority-type-auth")
      : navigate("/priority-type");
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.propertyTypePageContainer}>
      <div className={styles.propertyTypePageContainerSub}>
        <div>
          <span>Type of Property</span>
        </div>

        <div className={styles.imgnBtnContainer}>
          <div>
            <img
              src={PropertyTypeOne}
              onClick={() => propertyTypeHandler("Condominium")}
            />
            <button
              style={{
                backgroundColor:
                  propertyType === "Condominium" ? "#36B14D" : "",
                color: propertyType === "Condominium" ? "#fff" : "",
              }}
              className={BtnStyles.outlinedBtn}
              onClick={() => propertyTypeHandler("Condominium")}
            >
              Condominium
            </button>
          </div>

          <div>
            <img
              src={PropertyTypeTwo}
              onClick={() => propertyTypeHandler("HDB")}
            />
            <button
              style={{
                backgroundColor: propertyType === "HDB" ? "#36B14D" : "",
                color: propertyType === "HDB" ? "#fff" : "",
              }}
              className={BtnStyles.outlinedBtn}
              onClick={() => propertyTypeHandler("HDB")}
            >
              HDB
            </button>
          </div>

          <div>
            <img
              src={PropertyTypeThree}
              onClick={() => propertyTypeHandler("Landed")}
            />
            <button
              style={{
                backgroundColor: propertyType === "Landed" ? "#36B14D" : "",
                color: propertyType === "Landed" ? "#fff" : "",
              }}
              className={BtnStyles.outlinedBtn}
              onClick={() => propertyTypeHandler("Landed")}
            >
              Landed
            </button>
          </div>
        </div>

        <div className={styles.bottomContainer}>
          {windowWidth <= 490 ? (
            <>
              <div className={styles.bottomContainerResponsive}>
                <span>Number of Bedroom</span>
                <input
                  style={{
                    fontSize: "1.8rem",
                    padding: "4px",
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                  type="text"
                  placeholder=""
                  value={room}
                  onChange={(e) => noOfRoomsHandler(e.target.value)}
                ></input>
                <DragSlider />
              </div>
              <div className={styles.bottomContainerResponsiveBtn}>
                <button
                  className={BtnStyles.outlinedBtnBottom}
                  style={{ backgroundColor: "#36B14D", color: "#fff" }}
                  onClick={() => navigate("/home-type")}
                >
                  {" "}
                  Back{" "}
                </button>
                <button
                  style={{
                    backgroundColor: propertyType && room ? "#36B14D" : "",
                    color: propertyType && room ? "#fff" : "",
                  }}
                  disabled={!propertyType || !room}
                  className={BtnStyles.outlinedBtnBottom}
                  onClick={propertyTypeNextHandler}
                >
                  {" "}
                  Next{" "}
                </button>
              </div>
            </>
          ) : (
            <>
              <button
                className={BtnStyles.outlinedBtnBottom}
                style={{ backgroundColor: "#36B14D", color: "#fff" }}
                onClick={() => navigate("/home-type")}
              >
                {" "}
                Back{" "}
              </button>
              <div>
                <span>Number of Bedroom</span>
                <input
                  style={{
                    fontSize: "1.8rem",
                    padding: "4px",
                    textAlign: "center",
                    fontWeight: "500",
                  }}
                  type="text"
                  placeholder=""
                  value={room}
                  onChange={(e) => noOfRoomsHandler(e.target.value)}
                ></input>
                <DragSlider />
              </div>
              <button
                style={{
                  backgroundColor: propertyType && room ? "#36B14D" : "",
                  color: propertyType && room ? "#fff" : "",
                }}
                disabled={!propertyType || !room}
                className={BtnStyles.outlinedBtnBottom}
                onClick={propertyTypeNextHandler}
              >
                {" "}
                Next{" "}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PropertyTypePage;
