import React from 'react';
import { useRef,useState,useEffect } from 'react';
import Logo from '../../../images/logo.png'
import styles from './adminPdfMiniature.module.css'
// import html2canvas from 'html2canvas';
import QuotationHeader from "../../../images/quotationHeader.png"

const AdminPdfMiniature = ({pdfData }) => {
    const htmlContentRef = useRef(null);
    const[dataUrl,setdataUrl]= useState()
//   if (!isOpen) return null;

const inputDate = new Date(pdfData && pdfData.createdAt);
const options = { day: 'numeric', month: 'short', year: 'numeric' };
const formattedDate = inputDate.toLocaleDateString('en-US', options);

const [, month, day, year] = formattedDate?.match(/(\w+) (\d+), (\d+)/);
const rearrangedDate = `${day} ${month} ${year}`;

// const total = pdfData && pdfData.partDetails.reduce((total, el) => total + el.totalAmount, 0);
    const tableSpanData = 
    ["To be installed in Living Room(Central of the room) and Master Bedroom(Coverage Extension)",
     "To be installed in main entrance door",
     "To be installed in your preferred location",
     "To be installed within line of sight of the Aircon without blockage",
     "To be installed in bedroom and master bathroom",
     "To be installed in living room, foyer, kitchen and master bedroom",
     "To be installed in hallway",
     "To be installed at entrance door",
     "To be installed in your preferred location",
     "To be installed in your preferred location",
     "To be installed in your preferred location",
     "Central of the unit(near kitchen or hallway)"
    ]

    console.log(pdfData)
//   const captureImage = () => {
//     if (htmlContentRef.current) {
//       html2canvas(htmlContentRef.current)
//         .then((canvas) => {
//           const imgData = canvas.toDataURL('image/png');
//           setImgUrl(imgData)
//         })
//         .catch((error) => {
//           console.error('Error capturing image:', error);
//         });
//     }
//   };

//   useEffect(()=>{
//     captureImage();
//   },[])


  return (
    // <div className={styles.modal} onClick={onClose}>
    <div className={styles.modalContent} ref={htmlContentRef}>
    <div className={styles.modalContentSub} >
        <div className={styles.headerCont}>
            <img src={Logo} />
            <span>Quotation</span>
        </div>
        <div className={styles.detailsCont}>
            <div className={styles.addressCont} >
                <div className={styles.addressContTop}>
                    <span className={styles.heading}>
                    Energeek Pte Ltd
                    </span>
                    <span className={styles.headerOrgAddress}>
                    138, Robinson Road #02-26, Singapore 068906.
                    </span>
                </div>

                <div className={styles.addressContBottom}>
                    <div className={styles.addressContBottomLeft} >
                        <span style={{fontSize:'0.3rem'}} >Attn :</span>
                        <span style={{fontSize:'0.3rem'}} >Phone :</span>
                        <span style={{fontSize:'0.3rem'}} >Email :</span>
                    </div>
                    <div className={styles.addressContBottomRight} >
                        <span style={{fontSize:'0.3rem'}} >&nbsp;&nbsp;{pdfData && pdfData.user?.name}</span>
                        <span style={{fontSize:'0.3rem'}} >&nbsp;&nbsp;</span>
                        <span style={{fontSize:'0.3rem'}} >&nbsp;&nbsp;{pdfData && pdfData.user?.email}</span>
                    </div>
                </div>

            </div>
            <div className={styles.infoCont} >
                <div className={styles.infoContTop}>
                        <div className={styles.addressContBottomLeft} >
                            <span style={{fontSize:'0.3rem'}} >Date :</span>
                            <span style={{fontSize:'0.3rem'}} >Quotation ref :</span>
                        </div>
                        <div className={styles.addressContBottomRight} >
                            <span style={{fontSize:'0.3rem'}} >&nbsp;&nbsp;{pdfData && rearrangedDate}</span>
                            <span style={{fontSize:'0.3rem'}} >&nbsp;&nbsp;{pdfData && pdfData.quotationNumber}</span>
                        </div>
                </div>

                <div className={styles.infoContBottom}>
                        <div className={styles.addressContBottomLeft} >
                            <span style={{fontSize:'0.3rem'}}>Property Type :</span>
                            <span style={{fontSize:'0.3rem'}}>Number of rooms :</span>
                            <span style={{fontSize:'0.3rem'}} >Priority :</span>
                        </div>
                        <div className={styles.addressContBottomRight} >
                            <span style={{fontSize:'0.3rem'}} >&nbsp;&nbsp;{pdfData && `${pdfData.describe}  ${pdfData.propertyType}`}</span>
                            <span style={{fontSize:'0.3rem'}} >&nbsp;&nbsp;{pdfData && pdfData.rooms}</span>
                            <span style={{fontSize:'0.3rem'}} >&nbsp;&nbsp;{pdfData && pdfData.priority}</span>
                        </div>
                </div>
            </div>
        </div>
        <div className={styles.tableCont}>
            <table>
            <thead>
                <th
                style={{ width: "4.5%", textAlign: "right", paddingRight: "4px" }}
                >
                S/No
                </th>
                <th style={{ width: "30%",textAlign:'left',paddingLeft:'4px' }}>Description</th>
                <th
                style={{ width: "4.5%", textAlign: "left", paddingLeft: "4px" }}
                >
                UOM
                </th>
                <th
                style={{ width: "4.5%", textAlign: "right", paddingRight: "4px" }}
                >
                Qty
                </th>
                <th
                style={{ width: "5%", textAlign: "right", paddingRight: "4px" }}
                >
                Unit Price
                </th>
                <th
                style={{ width: "8.5%", textAlign: "right", paddingRight: "4px" }}
                >
                Amount
                </th>
            </thead>
            <tbody>
                <tr className={styles.firstRow}>
                    <td></td>
                    <td>SMART HOME</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>

                {pdfData && pdfData.partDetails.length >0 && 
                    pdfData.partDetails.map((el,index)=>(
                        <tr key={el.id}>
                        <td style={{ textAlign: "right", paddingRight: "4px",fontSize:'0.2rem',fontWeight:'600' }}>
                            {1 + index}
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                            <div className={styles.descDataCont} >
                                {el.productName}
                                <span style={{fontSize:'0.2rem'}} >{tableSpanData[index]}</span>
                            </div>
                            
                        </td>
                        <td style={{ textAlign: "left", paddingLeft: "4px" }}>
                            {el.UOM}
                        </td>
                        <td style={{ textAlign: "right", paddingRight: "4px" }}>
                            {el.quantity.toFixed(2)}
                        </td>
                        <td style={{ textAlign: "right", paddingRight: "4px" }}>
                            <div className={styles.numberCont} >
                                <span style={{paddingLeft:'5px',fontSize:'0.2rem'}}>$</span>
                                {el.price.toFixed(2)}
                            </div>
                        </td>
                        <td style={{ textAlign: "right", paddingRight: "4px" }}>
                        <div className={styles.numberCont} >
                                <span style={{paddingLeft:'5px',fontSize:'0.2rem'}}>$</span>
                                {el.totalAmount.toFixed(2)}
                            </div>
                        </td>
                        </tr>
                    ))
                }

                <tr>
                    <td style={{textAlign: "right", paddingRight: "4px",fontSize:'0.2rem',fontWeight:'600'}} >13</td>
                    <td style={{textAlign: "left", paddingLeft: "4px",fontSize:'0.2rem',fontWeight:'600'}} >Installation</td>
                    <td>lot</td>
                    <td style={{ textAlign: "right", paddingRight: "4px" }}>1.00</td>
                    <td style={{ textAlign: "right", paddingRight: "4px" }}>
                        <div className={styles.numberCont} >
                                    <span style={{paddingLeft:'5px',fontSize:'0.3rem'}}>$</span>
                                    {pdfData.installation?.toFixed(2)}
                        </div>
                        </td>
                    <td style={{ textAlign: "right", paddingRight: "4px" }}>
                    <div className={styles.numberCont} >
                                    <span style={{paddingLeft:'5px',fontSize:'0.3rem'}}>$</span>
                                    {pdfData.installation?.toFixed(2)}
                        </div>
                    </td>
                </tr>
            </tbody>

            <tfoot>
    <tr>
      <td colSpan="4" style={{border:'none'}}>
      </td>
      <td style={{ textAlign: "right", paddingRight: "4px", backgroundColor:'#36B14D' }}>Grand Total</td>
      <td style={{ textAlign: "right", paddingRight: "4px",backgroundColor:'#36B14D' }}>
        <div className={styles.numberCont} >
            <span style={{paddingLeft:'5px',fontSize:'0.3rem'}}>$</span>
           {pdfData &&(pdfData.totalAmount).toFixed(2)}
        </div>
      </td>
    </tr>
            </tfoot>

            </table>
        </div>
        <div className={styles.footerCont}>
            <span className={styles.footerContspan} style={{fontSize:'0.3rem'}} >Terms & Conditions</span>
            <div className={styles.footerContBottom} >
                        <div className={styles.footerContBottomSub} >
                            <span style={{fontSize:'0.2rem'}} >1</span>
                            <span style={{fontSize:'0.2rem'}} >This Quotation is valid for 30 calendar days from the date stated.</span>
                        </div>
                        <div className={styles.footerContBottomSub} >
                            <span style={{fontSize:'0.2rem'}} >2</span>
                            <span style={{fontSize:'0.2rem'}} >The payment term shall be 90 days upon receipt of invoice</span>
                        </div>
                        <div className={styles.footerContBottomSub} >
                            <span style={{fontSize:'0.2rem'}} >3</span>
                            <span style={{fontSize:'0.2rem'}} >This is a legal binding agreement of the sale of items listed above upon the approval and acceptance as below.</span>
                        </div>
                        <div className={styles.footerContBottomSub} >
                            <span style={{fontSize:'0.2rem'}} >4</span>
                            <span style={{fontSize:'0.2rem'}} >This quotation only includes the items expressly stated above and does not include any other variations not mentioned.</span>
                        </div>
                        <div className={styles.footerContBottomSub} >
                            <span style={{fontSize:'0.2rem'}} >5</span>
                            <span style={{fontSize:'0.2rem'}} >Sodexo Singapore Pte Ltd would not be liable for any repair work in the event of abuse, misuse, neglect, faulty installation or repair by unauthorized personnel.</span>
                        </div>
                        <div className={styles.footerContBottomSub} >
                            <span style={{fontSize:'0.2rem'}} >6</span>
                            <span style={{fontSize:'0.2rem'}} >This Quotation shall be read in conjunction with the submitted proposal as appended.</span>
                        </div>
            </div>
        </div>
        </div>
      </div>

    // </div>
  );
};

export default AdminPdfMiniature;
