import styles from './viewQuotationPage.module.css';
import RectangleImage from '../images/Rectangle.png'
import HeaderContainer from './components/headerContainer';
import BottomContainer from './components/bottomContainer';

function ViewQuotationPage(props){

    return(<div className={styles.homePageContainer}>
            <div className={styles.homePageContainerSub}>
        <HeaderContainer />
        <div className={styles.imgContainer}>
            <img src={RectangleImage} />
            <span>Project Overview</span>
        </div>
        <BottomContainer setHomeTypePg={props.setHomeTypePg}  />
        </div>
    </div>)
}

export default ViewQuotationPage;