import styles from './usersPage.module.css'
import { useState,useEffect } from 'react'
import TotalUsers from '../../images/TotalUsers.svg'
import SearchIcon from '../../images/searchIcon.svg'
import FilterIcon from '../../images/filterIcon.svg'
import { callGetApiSevices } from '../../webServices/api'
import AdminUsersTable from '../../components/table/adminUsersTable'

function UsersPage(){

    const[adminUsersTableData,setAdminUsersTableData] = useState([]);
    const[adminUsersData,setAdminUsersData] = useState({});
    const[pdfData,setPdfData] = useState();
    const[actualUsersTableData,setActualUsersTableData] = useState([]);
    const [searcedText,setSearchedText] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const[offset,setOffset] = useState(1);
    const itemsPerPage = 10;


    function getUsers(page){
        setOffset((page - 1)*itemsPerPage);
        if(searcedText){
            callGetApiSevices(`/user/users/?search=${searcedText}&limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`,
            (response)=>{
                if(response.status === 200){
                    setAdminUsersTableData(response.data.users);
                    setActualUsersTableData(response.data.users);
                    setAdminUsersData(response.data);
                }else{
                    alert('something went wrong')
                }
            },
            (err)=>{
                alert(err);
            }
            )
        }else{
        callGetApiSevices(`/user/users/?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}`,
        (response)=>{
            if(response.status === 200){
                setAdminUsersTableData(response.data.users);
                setActualUsersTableData(response.data.users);
                setAdminUsersData(response.data);
            }else{
                alert('something went wrong')
            }
        },
        (err)=>{
            alert(err);
        }
        )
    }
    }

    // useEffect(()=>{
    //     if(searcedText){
    //         const searchText = searcedText.toLowerCase();
    //         const filteredUsers = actualUsersTableData.filter(user =>
    //           user.name.toLowerCase().includes(searchText)
    //         );
    //         setAdminUsersTableData(filteredUsers);
    //     }else{
    //         console.log(actualUsersTableData,'hiiiiiiii')
    //         setAdminUsersTableData(actualUsersTableData);
    //     }
    // },[searcedText])

 
    useEffect(() => {
        getUsers(currentPage);
      }, [currentPage,searcedText]);
    
    const handlePageChangePrevious = (newPage) => {
     
        setCurrentPage(newPage);
    };

    const handlePageChangeNext = (newPage) => {
      
        setCurrentPage(newPage);
    };
    
console.log(currentPage*10,adminUsersData.totalUserCount )

    return(
    <div className={styles.usersPageCont}>
        <div className={styles.headingCont}>
            <div className={styles.headingContLeft}>
                <span>Users</span>

                <div className={styles.card}>
                <div className={styles.cardTop} >
                    <div className={styles.cardTopLeft} >
                        <span>{adminUsersData?.totalUserCount || 0}</span>
                        <span>Total Registered Users</span>
                    </div>
                    <img src={TotalUsers} />
                </div>

                <div className={styles.cardBottom}>
                    <div className={styles.cardBottomSub} >
                        <span style={{color:adminUsersData?.percentChange < 0 ? '#FD5942' : adminUsersData?.percentChange > 0 ? '#30C07D' : 'black'}} >{`${adminUsersData?.percentChange < 0 ? '↓' : adminUsersData?.percentChange > 0 ? '↑' : ''} ${adminUsersData?.percentChange || 0}%`}</span>
                        <span>Since Last Month</span>
                    </div>

                </div>
                </div>

                <div className={styles.searchnFiltCont} >
                <div className={styles.searchContainer}>
                    <div className={styles.searchBox}>
                        <img src={SearchIcon} alt="Search Icon"/>
                        <input type="text" placeholder="Search" onChange={(e)=>setSearchedText(e.target.value)} />
                    </div>
                </div>

                </div>

            </div>
            
        </div>

        <div className={styles.bottomCont} >
        <div className={styles.bottomContTable}>
            <AdminUsersTable getUsers={getUsers} adminUsersTableData={adminUsersTableData} setPdfData={setPdfData} offset={offset}/>
        </div>   
        </div>
        <div className={styles.paginationContainer}>
            <button onClick={() => handlePageChangePrevious(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </button>
            <div className={styles.currentPageCont} >
                <span>{currentPage}</span>
            </div>
            
            <button onClick={() => handlePageChangeNext(currentPage + 1)} disabled={currentPage*10 >= adminUsersData.count}>
              Next
            </button>
        </div>
    </div>
    )
}

export default UsersPage;