import React, { useEffect } from 'react';
import { useTable } from 'react-table';
import { useState } from 'react';
import styles from '../../adminPage/usersPage/usersPage.module.css'
import { callDeleteApiSevices, callGetApiSevices, callPatchApiSevices } from '../../webServices/api';
import SettingsIcon from '../../images/settingsTable.svg'
import ResetPasswordModal from '../modal/resetPasswordModal';
import CommonModal from '../modal/commonModal';

const AdminUsersTable = ({getUsers,adminUsersTableData, setPdfData,offset}) => {

  const [selectedRow, setSelectedRow] = useState();
  const [selectedRowData, setSelectedRowData] = useState();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [passwordModalOpen, setResetPasswordModal] = useState(false);
  const [userState,setUserState] = useState();
  const [deleteUser,setDeleteUser] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [modalMsg,setModalMsg] = useState('');
  const [modalSubMsg, setModalSubMsg] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  // const data = React.useMemo(
  //   () => quotationTableData,
  //   [quotationTableData]
  // );

  useEffect(()=>{
    if(adminUsersTableData.length>0){
      setSelectedRow(adminUsersTableData[0]._id)
      setSelectedRowData(adminUsersTableData[0])
    }
  },[adminUsersTableData])

  function passwordModalHandler(e){
    if(e.target.getAttribute('value') === 'overlay' || e.target.getAttribute('value') === 'tableBtn'){
    setResetPasswordModal(!passwordModalOpen);
    } else if(e.target.getAttribute('value') === 'resetBtn'){
      setResetPasswordModal(!passwordModalOpen);
      confirmationModalHandler();
      setModalMsg('Password updated successfully')
      }
  }




  const passwordMenuColumn = {
    Header: () => (
      <div className={styles.imageHeader}>
        <img src={SettingsIcon} alt="Icon" style={{ width: '13px', height: '13px' }} />
      </div>
    ),
    accessor: 'passwordMenu',
    width: 300,
    Cell: ({ row }) => {
      // console.log(row); // Log the row object
  
      return row.original._id === selectedRow ? (
        <>
        {passwordModalOpen && <ResetPasswordModal selectedRowData={selectedRowData} onHide={passwordModalHandler} selectedRowDataId={selectedRowData.original ? selectedRowData.original._id :selectedRowData._id}/>}
        <div className={styles.optionsMenuCont}>
          <button className={styles.resetBtn} value={'tableBtn'} onClick={passwordModalHandler}>
            Reset password
          </button>
          <button className={styles.optionsMenuBtn} onClick={toggleMenu}>
            More
          </button>
          {isMenuOpen && (
            <div className={styles.optionsMenu}>
              <div className={styles.menuItem} onClick={()=>setUserState(row.original.status === 'active'?'Suspend User':'Activate User')}>
                {row.original.status === 'active'?'Suspend User':'Activate User'}
              </div>
              <div className={styles.menuItem} onClick={()=>setDeleteUser(true)}>
                Delete User
              </div>
            </div>
          )}
        </div>
        </>
      ) : null;
    },
  };


  // console.log(selectedRowData)

  const data = React.useMemo(() => {
    if(adminUsersTableData.length === 0){
        return [];
      }
    return adminUsersTableData.map((item, index) => ({
      ...item,
      serialNumber: (index + 1)+offset,
    }));
  }, [adminUsersTableData]);

  // Column definitions
  const columns = React.useMemo(
    () =>{
    
      const baseColumns = [
      { Header: '#', accessor: 'serialNumber',width:100 },
      { Header: 'Name', accessor: 'name',width:100 },
      { Header: 'Email', accessor: 'email',width:200 },
      { Header: 'Status', accessor: 'status',width:100 },
      { Header: 'Quotation Generated', accessor: 'quotations.length' },
    ];

    if (selectedRow) {
      baseColumns.push(passwordMenuColumn);
    }
    

    return baseColumns;


    },
  
    [selectedRow,isMenuOpen,passwordModalOpen]
  );

  // Create an instance of the useTable hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });


  const handleRowClick = (rowId,row) => {
    setSelectedRowData(row)
    setSelectedRow(rowId == selectedRow ? rowId : rowId);

//     callGetApiSevices(`/quotations/${rowId}`,
//     (response)=>{
//       if(response.status === 200){
//           setPdfData(response.data);
//       }else{
//           alert('something went wrong')
//       }
//   },
//   (err)=>{
//     alert(err);
// }
//     )
  };

  useEffect(()=>{
    if(adminUsersTableData.length >0 && selectedRow == adminUsersTableData[0]._id){
      handleRowClick(selectedRow,selectedRowData)
    }
  },[selectedRow])

  function confirmationModalHandler(){
    setConfirmationModal(!confirmationModal);
    setModalMsg('');
  }

  function deleteUserHandler(){
    confirmationModalHandler();
    setDeleteUser(false);
  }

  useEffect(()=>{
    if(!userState) return;
    if(userState === 'Suspend User'){
      callPatchApiSevices(`/user/users/${selectedRowData.original ? selectedRowData.original._id :selectedRowData._id}`,
      {
        status:'suspended'
      },
      (response)=>{
        if(response.status === 200){
          console.log('suspend done')
          toggleMenu();
          getUsers();
          setUserState('');
          confirmationModalHandler();
          setModalMsg('Status updated successfully')
        }else{
            alert('error updating status')
            toggleMenu();
            setUserState('');
        }
    },
    (err)=>{
        alert(err);
        toggleMenu();
        setUserState('');
    }
      )
    }else  if(userState === 'Activate User'){
      callPatchApiSevices(`/user/users/${selectedRowData.original ? selectedRowData.original._id :selectedRowData._id}`,
      {
        status:'active'
      },
      (response)=>{
        if(response.status === 200){
          console.log('activation done done')
          toggleMenu();
          getUsers();
          setUserState('');
          confirmationModalHandler();
          setModalMsg('Status updated successfully')
        }else{
            alert('error updating status')
            toggleMenu();
            setUserState('');
        }
    },
    (err)=>{
        alert(err);
        toggleMenu();
        setUserState('');
    }
      )
    }
  },[userState])

    function userDeleteHandler(){
    if(deleteUser){
    callDeleteApiSevices(`/user/users/${selectedRowData.original ? selectedRowData.original._id :selectedRowData._id}`,
    (response)=>{
      if(response.status === 200){

        setTimeout(()=>{
          setModalSubMsg('');
          getUsers();
          setConfirmationModal(true);
          setModalMsg('User deleted successfully')
          setDeleteUser(false);
        },1000)

      }
    },
    (err)=>{
      console.log(err);
    }
    )
  }
  }

  useEffect(()=>{
    if(deleteUser){
      confirmationModalHandler();
      toggleMenu();
      setModalMsg('Are you sure you want to delete this user?');
      setModalSubMsg('This action cannot be undone. This will permanently delete the all the user related data, including all quotation generated. Please type the name of the workspace to confirm.')
    }
  },[deleteUser])

  return (
    <>
    {confirmationModal && <CommonModal deleteUser={deleteUserHandler} onHide={confirmationModalHandler} message={modalMsg} subMessage={modalSubMsg} handler={userDeleteHandler} />}
    <table {...getTableProps()} style={{ width: '100%',borderSpacing:0 , backgroundColor:'#FFF', borderRadius:'8px'}}>
      <thead >
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} >
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} style={{ paddingLeft: column.Header === '#' ? '5px' : '0',width: column.Header === '#' ? '50px' : 'auto', borderBottom:'1px solid #777', textAlign: column.Header === 'Quotation Generated' ? 'right' : 'left',paddingTop:'8px',paddingBottom:'8px',fontSize:'12px',fontWeight:'600',color:'#333' }} >{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          const isRowSelected = row.original._id == selectedRow;
          return (
            <tr {...row.getRowProps()}
            key={row.id}
            onClick={() => handleRowClick(row.original._id,row)}
            style={{
              background: isRowSelected ? '#ECEEEE' : 'transparent',
              cursor:'pointer'
              
            }}
            >
              {row.cells.map(cell => (
                <td style={{ textAlign:cell.column.Header === 'Quotation Generated'?'right':'left' , paddingLeft: cell.column.Header === '#' ? '5px' : '0', width: cell.column.width, borderBottom:'1px solid #777',paddingTop:'8px',paddingBottom:'8px',fontSize:'12px',fontWeight:'400',color:'#000' }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
    </>
  );
};

export default AdminUsersTable;
